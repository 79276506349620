import React, { useState, useRef, useEffect } from 'react';
import { queryChatMessage, getChatMessages, createChatMessage } from '../services/endpoints';
import ChatMessage from './ChatMessage';

const Query = ({ chatId, initialQuestion, initialResponse, existingChat }) => {
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const inputRef = useRef(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const messagesEndRef = useRef(null);
  const [chatDetails, setChatDetails] = useState(null);
  const initialLoadDone = useRef(false);

  useEffect(() => {
    const loadMessages = async () => {
      if (!chatId || initialLoadDone.current) return;
      
      try {
        setIsLoadingHistory(true);
        initialLoadDone.current = true;
        
        // If we have an initial response, use that instead of loading history
        if (initialResponse && initialQuestion) {
          const messages = [{
            sender: 'You',
            content: initialQuestion,
            type: 'user'
          }];
          
          if (initialResponse.answer || initialResponse.content) {
            messages.push({
              sender: 'AI',
              content: initialResponse.answer || initialResponse.content,
              type: initialResponse.type || 'text',
              file: initialResponse.file
            });
          }
          
          setChatHistory(messages);
          
          // Update chat details
          setChatDetails({
            title: 'New Chat',
            description: '',
            created_at: new Date().toISOString()
          });
          
          return;
        }

        // Otherwise load chat history from API
        const response = await getChatMessages(chatId);
        console.log("Chat response from API:", response);
        
        // Filter out duplicate messages by comparing content and sender
        const uniqueMessages = [];
        const seen = new Set();
        
        response.data.messages.forEach(msg => {
          const key = `${msg.sender}-${msg.content}`;
          if (!seen.has(key)) {
            seen.add(key);
            uniqueMessages.push(msg);
          }
        });
        
        const history = uniqueMessages.map(msg => ({
          sender: msg.sender === 'user' ? 'You' : 'AI',
          content: msg.content,
          type: msg.type || (msg.sender === 'user' ? 'user' : 'ai'),
          file: msg.file
        }));
        
        setChatHistory(history);
        
        // Update chat details
        setChatDetails({
          title: response.data.title,
          description: response.data.description,
          created_at: response.data.created_at
        });
        
      } catch (error) {
        console.error('Error loading chat history:', error);
        initialLoadDone.current = false; // Reset flag on error to allow retry
      } finally {
        setIsLoadingHistory(false);
      }
    };

    loadMessages();
    
    // Set up polling for updates after initial load
    const pollInterval = setInterval(async () => {
      if (!chatId) return;  // Skip if no chatId
      
      try {
        const response = await getChatMessages(chatId);
        
        // Filter out duplicate messages by comparing content and sender
        const uniqueMessages = [];
        const seen = new Set();
        
        response.data.messages.forEach(msg => {
          const key = `${msg.sender}-${msg.content}`;
          if (!seen.has(key)) {
            seen.add(key);
            uniqueMessages.push(msg);
          }
        });
        
        const history = uniqueMessages.map(msg => ({
          sender: msg.sender === 'user' ? 'You' : 'AI',
          content: msg.content,
          type: msg.type || (msg.sender === 'user' ? 'user' : 'ai'),
          file: msg.file
        }));
        
        setChatHistory(history);
        
        // Update chat details if changed
        setChatDetails(prevDetails => {
          const newDetails = {
            title: response.data.title,
            description: response.data.description,
            created_at: response.data.created_at
          };
          
          if (JSON.stringify(prevDetails) !== JSON.stringify(newDetails)) {
            console.log('Chat details updated:', newDetails);
            return newDetails;
          }
          return prevDetails;
        });
      } catch (error) {
        console.error("Error polling messages:", error);
      }
    }, 5000);
    
    return () => clearInterval(pollInterval);
  }, [chatId, initialQuestion, initialResponse]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!question.trim() || isLoading) {
      return;
    }

    const userMessage = {
      sender: 'You',
      content: question,
      type: 'user'
    };

    setChatHistory(prev => [...prev, userMessage]);
    setIsLoading(true);

    try {
      await createChatMessage(chatId, {
        content: question,
        sender: 'user'
      });

      const response = await queryChatMessage(chatId, question);
      
      const aiMessage = {
        sender: 'AI',
        content: response.data.answer || response.data.message || 'Sorry, I could not process your question.',
        type: 'ai'
      };

      setChatHistory(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Query error:', error);
      
      const errorMessage = {
        sender: 'AI',
        content: error.response?.data?.message || error.response?.data?.error || 'Sorry, an error occurred.',
        type: 'error'
      };

      setChatHistory(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
      setQuestion('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000); // Reset after 2 seconds
    });
  };

  return (
    <div>
      <div className="view-header">
        <h1 className="welcome-text">
          {chatDetails?.title || 'New Chat'}
        </h1>
        {chatDetails?.description && (
          <p className="view-description">
            {chatDetails.description}
          </p>
        )}
        <p className="view-date">
          {chatDetails?.created_at ? 
            `Created on ${new Date(chatDetails.created_at).toLocaleDateString()}` : 
            'Ask questions about your documents'
          }
        </p>
      </div>

      <div className="chat-container">
        <div className="chat-messages">
          {isLoadingHistory ? (
            <div className="chat-message">
              <div className="message-content">
                <i className="fas fa-spinner fa-spin"></i> Loading chat history...
              </div>
            </div>
          ) : (
            chatHistory.map((message, index) => (
              <ChatMessage 
                key={index}
                message={{
                  content: message.content,
                  sender: message.sender === 'You' ? 'user' : 'ai',
                  type: message.type,
                  file: message.file,
                  timestamp: new Date().toISOString()
                }}
                onCopy={(text) => copyToClipboard(text, index)}
                isCopied={copiedIndex === index}
              />
            ))
          )}
          {isLoading && (
            <div className="chat-message ai-message">
              <div className="message-sender">AI:</div>
              <div className="message-content">
                <i className="fas fa-spinner fa-spin"></i> Thinking...
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <form className="query-form" onSubmit={handleSubmit}>
        <div className="input-button-wrapper">
          <input 
            ref={inputRef}
            type="text" 
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Ask a question about your documents" 
            required 
            disabled={isLoading}
          />
          <button 
            type="submit" 
            className="chat-button"
            disabled={isLoading || !question.trim()}
          >
            {isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <i className="fas fa-paper-plane"></i>
            )}{' '}
            Ask AI
          </button>
        </div>
      </form>
    </div>
  );
};

export default Query;
