import React from 'react';
import MobileLayout from '../layout/MobileLayout';
import '../../../styles/mobile/files.scss';

const formatFileSize = (bytes) => {
  if (!bytes) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const getFileIcon = (fileType) => {
  switch (fileType) {
    case 'presentation':
      return '📽️';
    case 'document':
      return '📄';
    case 'spreadsheet':
      return '📊';
    case 'image':
      return '🖼️';
    case 'pdf':
      return '📑';
    default:
      return '📁';
  }
};

const FileItem = ({ file }) => {
  return (
    <div className="file-item">
      <div className="file-icon">{getFileIcon(file.type)}</div>
      <div className="file-info">
        <div className="file-name">{file.name}</div>
        <div className="file-meta">
          {file.size && <span>{formatFileSize(file.size)}</span>}
          {file.uploadedAt && <span>{formatDate(file.uploadedAt)}</span>}
        </div>
      </div>
      <div className="file-actions">
        <button className="action-button" onClick={() => window.open(file.url, '_blank')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" fill="currentColor"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

const MobileFiles = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  // Sample files array - in production this would come from your backend
  const [files] = React.useState([
    {
      id: 1,
      name: 'Quarterly Report.docx',
      type: 'document',
      size: 2500000,
      uploadedAt: '2024-03-21T10:30:00Z',
      url: '#'
    },
    {
      id: 2,
      name: 'Sales Data.xlsx',
      type: 'spreadsheet',
      size: 1800000,
      uploadedAt: '2024-03-21T11:15:00Z',
      url: '#'
    },
    {
      id: 3,
      name: 'Company Presentation.pptx',
      type: 'presentation',
      size: 5200000,
      uploadedAt: '2024-03-21T14:20:00Z',
      url: '#'
    }
  ]);

  // Filter files based on search query
  const filteredFiles = files.filter(file =>
    file.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <MobileLayout 
      onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      title="Files"
    >
      <div className="mobile-files">
        <div className="files-header">
          <div className="search-bar">
            <input 
              type="text" 
              placeholder="Search files..."
              className="search-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="files-list">
          {filteredFiles.map(file => (
            <FileItem key={file.id} file={file} />
          ))}
          {filteredFiles.length === 0 && (
            <div className="no-files">
              {searchQuery ? 'No matching files found' : 'No files uploaded yet'}
            </div>
          )}
        </div>

        <button className="floating-action-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2z"/>
          </svg>
        </button>
      </div>
    </MobileLayout>
  );
};

export default MobileFiles; 