import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, getUnreadNotificationsCount } from '../services/endpoints';
import { getProfilePhotoUrl } from '../utils/profileUtils';
import UploadModal from './UploadModal';
import NotificationsDropdown from './NotificationsDropdown';
import { trackEvent } from '../utils/analytics';

function AuthenticatedNavbar({ 
  onNotificationsClick, 
}) {
  const navigate = useNavigate();
  const [profile, setProfile] = React.useState({
    first_name: 'Guest',
    last_name: 'User',
    email: 'loading@example.com',
    goffer_email: 'loading@goffer.com'
  });
  const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [isProfileOpen, setIsProfileOpen] = React.useState(false);
  const profileRef = React.useRef(null);

  const fetchUnreadCount = async () => {
    try {
      const response = await getUnreadNotificationsCount();
      setUnreadCount(response.data.count);
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  React.useEffect(() => {
    // Initial fetch
    fetchUnreadCount();
    
    // Set up polling interval
    const interval = setInterval(fetchUnreadCount, 60000); // Poll every minute
    
    // Add global function to update count
    window.updateNotificationCount = fetchUnreadCount;
    
    // Cleanup interval on component unmount
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      // Remove global function
      delete window.updateNotificationCount;
    };
  }, []); // Empty dependency array to run only once on mount

  React.useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('jwt_token');
      if (!token) {
        console.log('No token found, redirecting to login');
        navigate('/login', { replace: true });
        return;
      }

      try {
        console.log('Fetching profile...');
        const response = await getUserProfile();
        console.log('Profile response:', response);
        
        // If we have a profile photo URL, get a fresh presigned URL
        if (response.data.profile_photo) {
          console.log('Profile photo URL found:', response.data.profile_photo);
          const presignedUrl = await getProfilePhotoUrl(response.data.profile_photo);
          console.log('Presigned URL generated:', presignedUrl);
          response.data.profile_photo = presignedUrl;
        }
        
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        if (error.response?.status === 401) {
          localStorage.removeItem('jwt_token');
          navigate('/login', { replace: true });
        }
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('jwt_token');
    navigate('/login', { replace: true });
  };

  const handleUploadClick = () => {
    setIsUploadModalOpen(true);
  };

  const handleNotificationsClick = () => {
    // Track notification icon click
    trackEvent('notification_icon_click', {
      unread_count: unreadCount,
      location: 'top_nav'
    });

    setIsNotificationsOpen(!isNotificationsOpen);
    // Fetch latest count when opening the dropdown
    if (!isNotificationsOpen) {
      fetchUnreadCount();
    }
    if (onNotificationsClick) {
      onNotificationsClick();
    }
  };

  const handleNotificationUpdate = () => {
    fetchUnreadCount();
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      <nav className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#f0f2f4] px-4 md:px-10 py-3">
        <div className="flex items-center gap-2 md:gap-4">
          <div className="text-[#111418] text-base md:text-lg font-bold">Goffer AI</div>
        </div>
        
        <div className="flex items-center gap-4">
          
          <div className="hidden md:flex items-center text-gray-600">
            Send documents to <span className="text-[#3498db] mx-2">{profile.goffer_email}</span> or
          </div>
          
          <button 
            onClick={handleUploadClick}
            className="flex items-center gap-2 px-4 py-2 bg-[#3498db] text-white rounded-lg text-sm font-medium hover:bg-[#2980b9] transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
            </svg>
            Upload a file
          </button>

          <div className="flex items-center gap-3">
            {/* Notifications */}
            <div className="relative">
              <button 
                className="relative p-2 hover:bg-gray-100 rounded-full"
                onClick={handleNotificationsClick}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                </svg>
                {unreadCount > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white text-xs w-4 h-4 flex items-center justify-center rounded-full">
                    {unreadCount}
                  </span>
                )}
              </button>
              <NotificationsDropdown 
                isOpen={isNotificationsOpen}
                onClose={() => setIsNotificationsOpen(false)}
                onNotificationUpdate={handleNotificationUpdate}
              />
            </div>

            {/* User Profile Icon */}
            <div className="relative" ref={profileRef}>
              <button
                onClick={() => setIsProfileOpen(!isProfileOpen)}
                className="relative p-2 hover:bg-gray-100 rounded-full"
              >
                <div className="w-[20px] h-[20px] rounded-full bg-gray-300 flex items-center justify-center overflow-hidden">
                  {profile.profile_photo ? (
                    <img 
                      src={profile.profile_photo} 
                      alt="Profile" 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24">
                      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                    </svg>
                  )}
                </div>
              </button>

              {isProfileOpen && (
                <div className="absolute right-0 mt-2 w-64 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 overflow-hidden">
                  {/* Profile Header */}
                  <div className="px-4 py-3 border-b border-gray-100">
                    <div className="text-sm font-medium text-gray-900">{profile.first_name} {profile.last_name}</div>
                    <div className="text-xs text-gray-500 truncate">{profile.email}</div>
                  </div>

                  {/* Menu Items */}
                  <div className="py-1" role="menu">
                    <button
                      onClick={() => {
                        setIsProfileOpen(false);
                        navigate('/settings');
                      }}
                      className="flex items-center w-full px-4 py-2.5 text-sm text-gray-700 bg-white hover:bg-[#f8f9fa] transition-colors"
                      role="menuitem"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-3 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      Settings
                    </button>

                    <div className="border-t border-gray-100"></div>

                    <button
                      onClick={handleLogout}
                      className="flex items-center w-full px-4 py-2.5 text-sm text-gray-700 bg-white hover:bg-[#f8f9fa] transition-colors group"
                      role="menuitem"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-3 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                      Sign out
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      <UploadModal 
        isOpen={isUploadModalOpen} 
        onClose={() => setIsUploadModalOpen(false)} 
      />
    </>
  );
}

export default AuthenticatedNavbar;

