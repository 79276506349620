import api from './api';

// Authentication
export const login = async (credentials) => {
  try {
    const response = await api.post('/api/login', credentials);
    return response;
  } catch (error) {
    console.error('Login error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

// File operations
export const uploadFile = async (formData) => {
  return await api.post('/api/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// Chat operations
export const getChats = () => {
  return api.get('/api/chats');
};

export const deleteChat = (chatId) => {
  return api.delete(`/api/chats/${chatId}`);
};

// Create a new empty chat
export const createNewChat = async () => {
  return await api.post('/api/chats');
};

// Send message and get AI response
export const queryChatMessage = async (chatId, question, options = {}) => {
  return await api.post(`/api/chats/${chatId}/query`, {
    question,
    files: options.files || []
  });
};

// Get chat messages history
export const getChatMessages = async (chatId) => {
  return await api.get(`/api/chats/${chatId}/messages`);
};

// Combined function for initial chat creation and first message
export const createChat = async (payload) => {
  // For new chats, we use chat_id = 0 to create a new chat with the first message
  const messagePayload = {
    content: payload.content,
    files: payload.files?.map(file => ({
      id: file.id,
      s3_url: file.s3_url,
      filename: file.filename,
      file_type: file.file_type
    })) || []
  };

  console.log('Creating chat with payload:', messagePayload);
  
  try {
    // Create new chat with first message
    const response = await api.post('/api/chats/0/messages', messagePayload);
    console.log('Create chat response:', response.data);
    
    // Query for AI response
    const queryResponse = await api.post(`/api/chats/${response.data.chat_id}/query`, {
      question: payload.content,
      files: messagePayload.files
    });
    
    return {
      chat: {
        id: response.data.chat_id,
        content: payload.content
      },
      initialResponse: queryResponse.data
    };
  } catch (error) {
    console.error('Error in createChat:', error);
    throw error;
  }
};

// Queries
export const queryDocuments = async (question) => {
  return await api.post('/api/query', { user: question });
};

// register a new user
export const register = async (userData) => {
  try {
    // Clone the data to prevent any reference issues
    const data = JSON.parse(JSON.stringify(userData));
    console.log('userData before request:', data);
    
    const response = await api.post('/api/register', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log('Register response:', response);
    return response;
  } catch (error) {
    console.error('Registration error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

// User profile
export const getUserProfile = async () => {
  try {
    const response = await api.get('/api/user/profile');
    return response;
  } catch (error) {
    console.error('Get user profile error:', error);
    throw error;
  }
};

// Add onboarding endpoint
export const completeOnboarding = async () => {
  try {
    const response = await api.post('/api/onboarding', {
      option: 'email',
      completed: true
    });
    return response;
  } catch (error) {
    console.error('Onboarding error:', error);
    throw error;
  }
};

// Add these new endpoints alongside your existing ones
export const forgotPassword = async (data) => {
  try {
    const response = await api.post('/api/forgot-password', data);
    return response;
  } catch (error) {
    console.error('Forgot password error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await api.post('/api/reset-password', data);
    return response;
  } catch (error) {
    console.error('Reset password error details:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });
    throw error;
  }
};

// Add other endpoints as needed...

export const createChatMessage = async (chatId, message) => {
  return await api.post(`/api/chats/${chatId}/messages`, message);
};

export const searchFiles = async (query, limit = 10, filters = {}) => {
  try {
    const response = await api.post('/api/search', {
      query,
      limit,
      filters
    });
    return response;
  } catch (error) {
    console.error('Search files error:', error);
    throw error;
  }
};

export const googleLogin = (data) => {
  return api.post('/api/auth/google', data);
};

// Get all notifications
export const getNotifications = async (page = 1, per_page = 10) => {
  try {
    console.log('[DEBUG] Fetching notifications with params:', { page, per_page });
    console.log('[DEBUG] Current URL:', window.location.href);
    console.log('[DEBUG] API base URL:', api.defaults.baseURL || 'Using relative URLs');
    
    const response = await api.get('/api/notifications', {
      params: {
        page,
        per_page
      }
    });
    
    console.log('[DEBUG] Notifications response status:', response.status);
    console.log('[DEBUG] Notifications count:', response.data?.notifications?.length || 'No notifications in response');
    
    return response;
  } catch (error) {
    console.error('[DEBUG] Error fetching notifications:', {
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      config: {
        url: error.config?.url,
        method: error.config?.method,
        baseURL: error.config?.baseURL,
        params: error.config?.params
      }
    });
    throw error;
  }
};

// Get count of unread notifications
export const getUnreadNotificationsCount = async () => {
  try {
    const response = await api.get('/api/notifications/unread-count');
    return response;
  } catch (error) {
    console.error('Error fetching unread count:', error);
    return { data: { count: 0 } };
  }
};

// Update notification status
export const updateNotification = async (notificationId, data) => {
  try {
    const response = await api.patch(`/api/notifications/${notificationId}`, data);
    return response;
  } catch (error) {
    console.error('Update notification error:', error);
    throw error;
  }
};

// Add this new function to handle messages with files
export const createChatMessageWithFiles = async (chatId, message, files) => {
  // First upload the files
  const formData = new FormData();
  
  // Add files to formData with the key 'file'
  files.forEach(fileObj => {
    formData.append('file', fileObj.file);
  });

  try {
    // First upload the files
    const uploadResult = await api.post('/upload', formData);

    if (uploadResult.success) {
      // Now create the chat message with the uploaded file URLs
      const messageResult = await api.post(`/chats/${chatId}/messages`, {
        message: message || '',
        files: uploadResult.files
      });

      return {
        ...messageResult,
        uploadedFiles: uploadResult.files
      };
    } else {
      throw new Error(uploadResult.message || 'File upload failed');
    }
  } catch (error) {
    console.error('Error in createChatMessageWithFiles:', error);
    throw error;
  }
};

// Get notification preferences
export const getNotificationPreferences = async () => {
  try {
    const response = await api.get('/api/user/notification-preferences');
    return response;
  } catch (error) {
    console.error('Get notification preferences error:', error);
    throw error;
  }
};

// Update notification preferences
export const updateNotificationPreferences = async (preferences) => {
  try {
    const response = await api.post('/api/user/notification-preferences', preferences);
    return response;
  } catch (error) {
    console.error('Update notification preferences error:', error);
    throw error;
  }
};

export const getFileUrl = async (fileId) => {
  const response = await api.get(`/api/files/${fileId}/view`);
  return response.data;
};

export const getProfile = async () => {
    try {
        const response = await api.get('/api/user/profile');
        return response;
    } catch (error) {
        console.error('Error fetching profile:', error);
        throw error;
    }
};

// Logout
export const logout = async () => {
  try {
    const response = await api.post('/api/logout');
    return response;
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const getSuggestedQuestions = async () => {
  try {
    const response = await api.get('/api/suggested-questions');
    return response;
  } catch (error) {
    console.error('Error fetching suggested questions:', error);
    throw error;
  }
};

export const updateProfilePicture = async (file) => {
  const formData = new FormData();
  formData.append('profile_photo', file);
  
  try {
    const response = await api.post('/api/user/profile/photo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    console.error('Update profile picture error:', error);
    throw error;
  }
};

export const uploadUrl = async (url) => {
  try {
    const response = await api.post('/api/upload/url', { 
      url: url.trim()
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 60000 // 60 second timeout for potentially large pages
    });
    return response;
  } catch (error) {
    console.error('URL upload error:', error.response?.data || error.message);
    throw error;
  }
};

export const sendContactMessage = async (formData) => {
  try {
    const response = await api.post('/api/contact', formData);
    return response;
  } catch (error) {
    console.error('Send contact message error:', error);
    throw error;
  }
};

export const updateProfile = async (profileData) => {
  return await api.patch('/api/user/profile', profileData);
};

export const getFileContent = async (fileId) => {
  try {
    const response = await api.get(`/api/files/${fileId}/content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching file content:', error);
    throw error;
  }
};