import React from 'react';
import { getDisplayValue } from '../../../utils/enumMappings';
import { 
  REMINDER_TYPE_DISPLAY, 
  REMINDER_STATUS_DISPLAY, 
  REMINDER_PRIORITY_DISPLAY 
} from '../../../utils/enumMappings';

const MobileReminderDetails = ({ 
  reminder, 
  isEditing, 
  editedReminder, 
  setEditedReminder,
  onEdit,
  onSave,
  onCancel,
  fileUrl
}) => {
  const PRIORITY_OPTIONS = ['HIGH', 'MEDIUM', 'LOW'];
  const STATUS_OPTIONS = ['PENDING', 'ACKNOWLEDGED', 'COMPLETED', 'DISMISSED'];
  const TYPE_OPTIONS = ['PAYMENT_DUE', 'EXPIRATION', 'RENEWAL', 'APPOINTMENT', 'CUSTOM'];

  if (!reminder) {
    return (
      <div className="flex items-center justify-center h-full text-gray-500 p-4">
        Select a reminder to view details
      </div>
    );
  }

  return (
    <div className="mobile-reminder-details bg-white h-full overflow-y-auto">
      <div className="p-4">
        {/* Header with Title and Edit Button */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex-1">
            {isEditing ? (
              <div className="space-y-3">
                <input
                  type="text"
                  value={editedReminder?.title || ''}
                  onChange={(e) => setEditedReminder(prev => ({ ...prev, title: e.target.value }))}
                  className="text-xl font-bold w-full p-2 border rounded"
                />
                <textarea
                  value={editedReminder?.original?.description || ''}
                  onChange={(e) => setEditedReminder(prev => ({ 
                    ...prev, 
                    original: { ...prev.original, description: e.target.value }
                  }))}
                  className="w-full p-2 border rounded text-gray-600"
                  rows="3"
                  placeholder="Add a description..."
                />
              </div>
            ) : (
              <>
                <h1 className="text-xl font-bold mb-2">{reminder.title}</h1>
                {reminder?.original?.description && (
                  <p className="text-gray-600">{reminder.original.description}</p>
                )}
              </>
            )}
          </div>
          <div className="ml-4">
            {isEditing ? (
              <div className="flex gap-2">
                <button 
                  onClick={onCancel}
                  className="px-3 py-1 bg-gray-400 text-white rounded-lg text-sm"
                >
                  Cancel
                </button>
                <button 
                  onClick={onSave}
                  className="px-3 py-1 bg-blue-600 text-white rounded-lg text-sm"
                >
                  Save
                </button>
              </div>
            ) : (
              <button 
                onClick={onEdit}
                className="px-3 py-1 bg-blue-400 text-white rounded-lg text-sm"
              >
                Edit
              </button>
            )}
          </div>
        </div>

        {/* Metadata Fields */}
        <div className="space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-medium mb-2">Due Date</h3>
            {isEditing ? (
              <input
                type="date"
                value={editedReminder?.due_date || ''}
                onChange={(e) => setEditedReminder(prev => ({ ...prev, due_date: e.target.value }))}
                className="w-full p-2 border rounded"
              />
            ) : (
              <span>{reminder?.original?.due_date || 'No due date'}</span>
            )}
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-medium mb-2">Priority</h3>
            {isEditing ? (
              <select
                value={editedReminder?.priority || 'LOW'}
                onChange={(e) => setEditedReminder(prev => ({ ...prev, priority: e.target.value }))}
                className="w-full p-2 border rounded"
              >
                {PRIORITY_OPTIONS.map(option => (
                  <option key={option} value={option}>
                    {getDisplayValue(REMINDER_PRIORITY_DISPLAY, option)}
                  </option>
                ))}
              </select>
            ) : (
              <span className={`px-2 py-1 rounded ${
                reminder?.priority === 'HIGH' ? 'bg-red-100 text-red-800' :
                reminder?.priority === 'MEDIUM' ? 'bg-yellow-100 text-yellow-800' :
                'bg-green-100 text-green-800'
              }`}>
                {getDisplayValue(REMINDER_PRIORITY_DISPLAY, reminder?.priority) || 'Low'}
              </span>
            )}
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-medium mb-2">Status</h3>
            {isEditing ? (
              <select
                value={editedReminder?.status || 'PENDING'}
                onChange={(e) => setEditedReminder(prev => ({ ...prev, status: e.target.value }))}
                className="w-full p-2 border rounded"
              >
                {STATUS_OPTIONS.map(option => (
                  <option key={option} value={option}>
                    {getDisplayValue(REMINDER_STATUS_DISPLAY, option)}
                  </option>
                ))}
              </select>
            ) : (
              <span className="px-2 py-1 rounded bg-blue-100 text-blue-800">
                {getDisplayValue(REMINDER_STATUS_DISPLAY, reminder?.status) || 'Pending'}
              </span>
            )}
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-medium mb-2">Type</h3>
            {isEditing ? (
              <select
                value={editedReminder?.category || 'CUSTOM'}
                onChange={(e) => setEditedReminder(prev => ({ ...prev, category: e.target.value }))}
                className="w-full p-2 border rounded"
              >
                {TYPE_OPTIONS.map(option => (
                  <option key={option} value={option}>
                    {getDisplayValue(REMINDER_TYPE_DISPLAY, option)}
                  </option>
                ))}
              </select>
            ) : (
              <span>{getDisplayValue(REMINDER_TYPE_DISPLAY, reminder?.original?.type) || 'General'}</span>
            )}
          </div>

          {/* Document Preview */}
          {(reminder?.original?.file_info || fileUrl) && (
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              {reminder?.original?.file_info && fileUrl ? (
                <embed 
                  src={fileUrl}
                  type={reminder.original.file_info.file_type}
                  width="100%"
                  height="300px"
                  className="border-0"
                />
              ) : (
                <div className="flex items-center justify-center h-[300px] text-gray-500">
                  {fileUrl === null ? 'No document available' : 'Loading document...'}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileReminderDetails; 