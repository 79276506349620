import React, { useState, useEffect } from 'react';
import * as endpoints from '../services/endpoints';

function UploadModal({ isOpen, onClose }) {
  const [link, setLink] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  // Reset states when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      setLink('');
      setUploadStatus(null);
      setUploading(false);
    }
  }, [isOpen]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleUrlUpload = async () => {
    if (!link.trim()) return;

    setUploading(true);
    setUploadStatus(null);

    try {
      await endpoints.uploadUrl(link);
      setUploadStatus({
        success: true,
        message: 'URL content uploaded successfully'
      });
      setLink('');
    } catch (error) {
      setUploadStatus({
        success: false,
        message: error.response?.data?.message || 'Failed to upload URL content'
      });
    } finally {
      setUploading(false);
    }
  };

  const getFileTypeIcon = (fileType) => {
    if (fileType.includes('wordprocessingml.document') || fileType.includes('.docx')) {
      return '📄';
    } else if (fileType.includes('spreadsheetml.sheet') || fileType.includes('.xlsx')) {
      return '📊';
    } else if (fileType.includes('presentationml.presentation') || fileType.includes('.pptx')) {
      return '📽️';
    } else if (fileType.includes('pdf')) {
      return '📑';
    } else if (fileType.includes('text')) {
      return '📝';
    } else if (fileType.includes('image')) {
      return '🖼️';
    }
    return '📎';
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    // Validate file sizes
    const MAX_FILE_SIZE = 16 * 1024 * 1024; // 16MB
    const oversizedFiles = Array.from(files).filter(file => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      setUploadStatus({
        success: false,
        message: `The following files exceed the 16MB size limit: ${oversizedFiles.map(f => f.name).join(', ')}`
      });
      return;
    }

    setUploading(true);
    setUploadStatus(null);

    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('file', file);
    });

    try {
      const response = await endpoints.uploadFile(formData);
      console.log('Upload response:', response);
      
      if (response.data.success) {
        setUploadStatus({
          success: true,
          message: `Successfully uploaded ${files.length} file(s)`,
          files: response.data.files
        });
      } else {
        throw new Error(response.data.message || 'Upload failed');
      }
    } catch (error) {
      console.error("Upload error details:", {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        headers: error.response?.headers
      });
      
      setUploadStatus({
        success: false,
        message: error.response?.data?.message || 'Upload failed'
      });
    } finally {
      setUploading(false);
      event.target.value = ''; // Reset file input
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-xl w-full max-w-xl mx-4">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">Upload Files</h2>
          <p className="text-gray-600 mb-6">Insert a link or upload a document</p>

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Link</label>
              <div className="flex gap-2">
                <input
                  type="text"
                  placeholder="https://"
                  value={link}
                  onChange={handleLinkChange}
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  onClick={handleUrlUpload}
                  disabled={uploading || !link.trim()}
                  className="px-4 py-2 bg-[#3498db] text-white rounded-lg text-sm font-medium hover:bg-[#2980b9] transition-colors disabled:opacity-50"
                >
                  Upload URL
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Upload files</label>
              <div 
                className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
                onClick={() => document.getElementById('file-upload').click()}
              >
                <div className="flex flex-col items-center">
                  <svg className="w-8 h-8 text-gray-400 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                  </svg>
                  <p className="text-gray-600">Upload one or more files</p>
                  <p className="text-gray-500 text-sm mt-1">Supported: PDF, Word, Excel, PowerPoint, Text, Images</p>
                </div>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  className="hidden"
                  onChange={handleFileUpload}
                  accept=".txt,.pdf,.doc,.docx,.xlsx,.csv,.png,.jpg,.jpeg,.pptx"
                />
              </div>
            </div>
          </div>

          {uploadStatus && (
            <div className={`mt-4 p-3 rounded-lg ${uploadStatus.success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}>
              <p>{uploadStatus.message}</p>
              {uploadStatus.success && uploadStatus.files && (
                <ul className="mt-2 space-y-1">
                  {uploadStatus.files.map((file, index) => (
                    <li key={file.id || index} className="flex items-center">
                      <span className="mr-2">{getFileTypeIcon(file.file_type || file.filename)}</span>
                      <span className="flex-1 truncate">{file.filename}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {uploading && (
            <div className="mt-4 p-3 rounded-lg bg-blue-50 text-blue-700">
              <div className="flex items-center">
                <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>Uploading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadModal; 