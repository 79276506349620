import React, { useState, useEffect } from 'react';
import { getNotifications, getFileUrl, updateNotification } from '../../../services/endpoints';
import MobileLayout from '../layout/MobileLayout';
import MobileReminderDetails from './MobileReminderDetails';
import { trackEvent } from '../../../utils/analytics';
import { getDisplayValue, REMINDER_TYPE_DISPLAY } from '../../../utils/enumMappings';
import '../../../styles/mobile/reminders.scss';

const MobileReminders = () => {
  const [reminders, setReminders] = useState([]);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedReminder, setEditedReminder] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    fetchReminders();
  }, []);

  const fetchReminders = async () => {
    try {
      setLoading(true);
      const response = await getNotifications(1);
      const notificationsAsReminders = response.data.notifications.map(notification => ({
        id: notification.id,
        title: notification.title,
        completed: notification.status === 'COMPLETED',
        date: new Date(notification.created_at).toLocaleDateString(),
        category: notification.type,
        priority: notification.priority,
        status: notification.status,
        isNew: notification.status === 'PENDING',
        original: {
          ...notification,
          file_info: notification.file_info,
          status: notification.status
        }
      }));
      setReminders(notificationsAsReminders);
      setError(null);
    } catch (err) {
      console.error('Error fetching notifications:', err);
      setError('Failed to load reminders');
    } finally {
      setLoading(false);
    }
  };

  const handleReminderSelect = async (reminder) => {
    setSelectedReminder(reminder);
    setShowDetails(true);
    
    if (reminder?.original?.file_info?.id) {
      try {
        const response = await getFileUrl(reminder.original.file_info.id);
        setFileUrl(response.url);
      } catch (error) {
        console.error('Error fetching file URL:', error);
      }
    } else {
      setFileUrl(null);
    }

    // Track reminder view
    trackEvent('reminder_view', {
      reminder_id: reminder.id,
      reminder_type: reminder.category,
      reminder_status: reminder.status
    });
  };

  const handleToggleComplete = async (reminderId) => {
    const reminder = reminders.find(r => r.id === reminderId);
    if (!reminder) return;

    try {
      const newStatus = reminder.status === 'COMPLETED' ? 'ACKNOWLEDGED' : 'COMPLETED';
      await updateNotification(reminderId, { status: newStatus });

      // Update local state
      setReminders(prevReminders =>
        prevReminders.map(r =>
          r.id === reminderId
            ? { ...r, status: newStatus, completed: newStatus === 'COMPLETED' }
            : r
        )
      );

      // Update selected reminder if it's the one being modified
      if (selectedReminder?.id === reminderId) {
        setSelectedReminder(prev => ({
          ...prev,
          status: newStatus,
          completed: newStatus === 'COMPLETED'
        }));
      }
    } catch (error) {
      console.error('Error updating reminder status:', error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedReminder(selectedReminder);
  };

  const handleSave = async () => {
    try {
      await updateNotification(selectedReminder.id, {
        title: editedReminder.title,
        description: editedReminder.original?.description,
        due_date: editedReminder.due_date,
        priority: editedReminder.priority,
        status: editedReminder.status,
        type: editedReminder.category
      });

      // Update local state
      const updatedReminder = {
        ...selectedReminder,
        ...editedReminder,
        original: {
          ...selectedReminder.original,
          ...editedReminder.original
        }
      };

      setReminders(prev =>
        prev.map(r => r.id === selectedReminder.id ? updatedReminder : r)
      );
      setSelectedReminder(updatedReminder);
      setIsEditing(false);
      setEditedReminder(null);
    } catch (error) {
      console.error('Error updating reminder:', error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedReminder(null);
  };

  const handleBack = () => {
    setShowDetails(false);
    setSelectedReminder(null);
    setIsEditing(false);
    setEditedReminder(null);
  };

  const renderContent = () => {
    if (showDetails) {
      return (
        <MobileReminderDetails
          reminder={selectedReminder}
          isEditing={isEditing}
          editedReminder={editedReminder}
          setEditedReminder={setEditedReminder}
          onEdit={handleEdit}
          onSave={handleSave}
          onCancel={handleCancel}
          fileUrl={fileUrl}
        />
      );
    }

    return (
      <div className="mobile-reminders-list">
        {loading ? (
          <div className="loading-state">Loading reminders...</div>
        ) : error ? (
          <div className="error-state">{error}</div>
        ) : (
          <div className="tasks-list p-4">
            {reminders.map(reminder => (
              <div
                key={reminder.id}
                className={`bg-white rounded-lg shadow-sm border border-gray-200 mb-3 ${
                  reminder.completed ? 'opacity-75' : ''
                }`}
                onClick={() => handleReminderSelect(reminder)}
              >
                <div className="p-4">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={reminder.status === 'COMPLETED'}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleToggleComplete(reminder.id);
                      }}
                      className="h-5 w-5 rounded border-gray-300"
                    />
                    <div className={reminder.status === 'COMPLETED' ? 'line-through text-gray-500' : ''}>
                      <div className="font-medium flex items-center">
                        {reminder.title}
                        {reminder.isNew && (
                          <span className="ml-2 w-2 h-2 rounded-full bg-blue-500"></span>
                        )}
                      </div>
                      <div className="text-sm text-gray-500 mt-1">
                        {getDisplayValue(REMINDER_TYPE_DISPLAY, reminder.category)} • {reminder.date}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <MobileLayout 
      title={showDetails ? "Reminder Details" : "Reminders"}
      onBack={showDetails ? handleBack : undefined}
    >
      <div className="mobile-reminders">
        {renderContent()}
      </div>
    </MobileLayout>
  );
};

export default MobileReminders; 