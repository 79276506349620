import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export function ChatProvider({ children }) {
  const [showChat, setShowChat] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatId, setChatId] = useState(null);

  const handleChatSelect = (chat) => {
    setShowChat(true);
    setSelectedChat(chat);
    setChatId(chat.id);
  };

  return (
    <ChatContext.Provider value={{
      showChat,
      setShowChat,
      selectedChat,
      setSelectedChat,
      chatId,
      setChatId,
      handleChatSelect
    }}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChatContext() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
} 