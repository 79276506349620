import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../services/endpoints';
import { trackEvent } from '../utils/analytics';
import { Helmet } from 'react-helmet-async';

function Register() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    mobile_phone: '',
    password: '',
    confirm_password: '',
    smsConsent: false
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    // Validate mobile phone and SMS consent
    if (formData.mobile_phone && !formData.smsConsent) {
      setError('SMS consent is required when providing a mobile phone number');
      setIsLoading(false);
      return;
    }

    try {
      const response = await register(formData);
      if (response.data.access_token) {
        trackEvent('registration_success', { 
          method: 'email',
          sms_opted_in: formData.smsConsent
        });
        localStorage.setItem('jwt_token', response.data.access_token);
        navigate('/onboarding');
      }
    } catch (error) {
      trackEvent('registration_error', { 
        error_type: error.response?.data?.error || 'unknown_error'
      });
      setError(error.response?.data?.error || 'Registration failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Register - Goffer.AI | Create Your Account</title>
        <meta name="description" content="Sign up for Goffer.AI to start managing your documents intelligently. Get smart alerts, automatic date detection, and AI-powered document processing." />
        <meta name="robots" content="noindex, nofollow" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Register - Goffer.AI | Create Your Account" />
        <meta property="og:description" content="Join Goffer.AI today and transform how you manage documents with AI-powered assistance." />
        <meta property="og:image" content="https://goffer.ai/register-og.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Register - Goffer.AI | Create Your Account" />
        <meta name="twitter:description" content="Join Goffer.AI today and transform how you manage documents with AI-powered assistance." />
        <meta name="twitter:image" content="https://goffer.ai/register-twitter.jpg" />
      </Helmet>

      <div className="flex min-h-screen items-center justify-center">
        <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
          <h2 className="text-[#1980e6] text-base font-medium text-center">Register for Goffer AI</h2>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              {error}
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="text-sm text-[#111418]">First Name *</label>
              <input 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
                type="text"
                name="first_name"
                value={formData.first_name}
                placeholder="First Name"
                required
                onChange={handleInputChange}
                autoComplete="given-name"
              />
            </div>

            <div>
              <label className="text-sm text-[#111418]">Last Name *</label>
              <input 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
                type="text"
                name="last_name"
                value={formData.last_name}
                placeholder="Last Name"
                required
                onChange={handleInputChange}
                autoComplete="family-name"
              />
            </div>

            <div>
              <label className="text-sm text-[#111418]">Username *</label>
              <input 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
                type="text"
                name="username"
                value={formData.username}
                placeholder="Username"
                required
                onChange={handleInputChange}
                autoComplete="username"
              />
            </div>

            <div>
              <label className="text-sm text-[#111418]">Email *</label>
              <input 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
                type="email"
                name="email"
                value={formData.email}
                placeholder="Email"
                required
                onChange={handleInputChange}
                autoComplete="email"
              />
            </div>

            <div>
              <label className="text-sm text-[#111418]">Mobile Phone</label>
              <input 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
                type="tel"
                name="mobile_phone"
                value={formData.mobile_phone}
                placeholder="Mobile Phone"
                onChange={handleInputChange}
                autoComplete="tel"
              />
            </div>

            <div className="mt-4">
              <div className="flex items-start gap-3">
                <input 
                  type="checkbox" 
                  id="smsConsent"
                  name="smsConsent"
                  className="mt-1"
                  checked={formData.smsConsent}
                  onChange={handleInputChange}
                  required={!!formData.mobile_phone}
                />
                <label htmlFor="smsConsent" className="text-sm text-[#637588]">
                  {formData.mobile_phone ? (
                    <span className="font-medium">I consent to receive SMS notifications</span>
                  ) : (
                    <span className="font-medium">Consent to receive SMS notifications</span>
                  )}
                  {' '}from Goffer.ai. 
                  Message & data rates may apply. Reply STOP to opt-out. 
                  <Link 
                    to="/terms" 
                    className="text-[#1980e6] hover:underline ml-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Terms
                  </Link>
                </label>
              </div>
            </div>

            <div>
              <label className="text-sm text-[#111418]">Password *</label>
              <input 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
                type="password"
                name="password"
                value={formData.password}
                placeholder="Password"
                required
                onChange={handleInputChange}
                autoComplete="new-password"
              />
              <p className="mt-1 text-xs text-gray-500">
                Password must be at least 8 characters and contain uppercase, lowercase, number, and special character
              </p>
            </div>

            <div>
              <label className="text-sm text-[#111418]">Confirm Password *</label>
              <input 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
                type="password"
                name="confirm_password"
                value={formData.confirm_password}
                placeholder="Confirm Password"
                required
                onChange={handleInputChange}
                autoComplete="new-password"
              />
            </div>

            <button 
              type="submit"
              className="w-full bg-[#1980e6] text-white py-2 rounded-lg hover:bg-[#1666b8] transition-colors"
              disabled={isLoading}
            >
              {isLoading ? 'Registering...' : 'Register'}
            </button>

            <p className="text-center mt-4 text-sm text-[#637588]">
              Already have an account?{' '}
              <Link to="/login" className="text-[#1980e6] hover:underline">
                Log in
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default Register;