import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getUserProfile, updateProfilePicture, getNotificationPreferences, updateNotificationPreferences, updateProfile } from '../services/endpoints';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { trackEvent } from '../utils/analytics';
import { getProfilePhotoUrl } from '../utils/profileUtils';

function Settings() {
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile_phone: '',
    goffer_email: '',
    profile_photo: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const fileInputRef = useRef(null);
  const [notificationPrefs, setNotificationPrefs] = useState({
    emailNotifications: true,
    pushNotifications: true,
    smsNotifications: false,
    smsOptIn: false,
    smsOptInDate: null
  });
  const [showSmsConsent, setShowSmsConsent] = useState(false);
  const [smsConsentChecked, setSmsConsentChecked] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('Attempting to fetch profile and notification preferences...');
      
      // Fetch profile and preferences separately to handle errors individually
      const profileRes = await getUserProfile().catch(err => {
        console.error('Profile fetch failed:', err);
        throw new Error('Failed to fetch user profile');
      });
      
      const prefsRes = await getNotificationPreferences().catch(err => {
        console.error('Preferences fetch failed:', err);
        // Return default preferences if the fetch fails
        return {
          data: {
            emailNotifications: true,
            pushNotifications: true
          }
        };
      });

      console.log('Profile response:', profileRes);
      console.log('Preferences response:', prefsRes);

      // If we have a profile photo URL, get a fresh presigned URL
      if (profileRes.data.profile_photo) {
        console.log('Profile photo URL found:', profileRes.data.profile_photo);
        const presignedUrl = await getProfilePhotoUrl(profileRes.data.profile_photo);
        console.log('Presigned URL generated:', presignedUrl);
        profileRes.data.profile_photo = presignedUrl;
      }

      setProfile(profileRes.data);
      // Ensure we have valid preferences data
      if (prefsRes && prefsRes.data) {
        setNotificationPrefs(prefsRes.data);
      }
      setRetryCount(0); // Reset retry count on successful fetch
      setLoading(false);
    } catch (err) {
      console.error('Data fetch error:', err);
      setError(err.message || 'An error occurred while loading settings');
      // Implement exponential backoff for retries
      if (retryCount < 3) {
        const delay = Math.pow(2, retryCount) * 1000; // 1s, 2s, 4s
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          fetchData();
        }, delay);
      }
      setLoading(false);
    }
  }, [retryCount]); // Add retryCount as dependency

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNotificationChange = async (field, value) => {
    try {
      if (field === 'smsNotifications' && value && !notificationPrefs.smsOptIn) {
        setShowSmsConsent(true);
        return;
      }

      const updatedPrefs = {
        ...notificationPrefs,
        [field]: value
      };

      // If turning on SMS notifications, include consent
      if (field === 'smsNotifications' && value) {
        updatedPrefs.smsOptInConsent = true;
      }

      setNotificationPrefs(updatedPrefs);
      await updateNotificationPreferences(updatedPrefs);
      
      // Track the notification preference change
      trackEvent('notification_preference_change', {
        preference_type: field,
        new_value: value
      });
      
      toast.success('Notification preferences updated');
    } catch (err) {
      console.error('Error updating notification preferences:', err);
      if (err.response?.data?.code === 'SMS_CONSENT_REQUIRED') {
        setShowSmsConsent(true);
        return;
      }
      toast.error('Failed to update notification preferences');
      // Revert the change
      setNotificationPrefs(prevPrefs => ({
        ...prevPrefs
      }));
    }
  };

  const handleSmsConsentSubmit = async () => {
    if (!smsConsentChecked) {
      toast.error('Please accept the SMS terms to enable SMS notifications');
      return;
    }

    try {
      const updatedPrefs = {
        ...notificationPrefs,
        smsNotifications: true,
        smsOptInConsent: true
      };

      await updateNotificationPreferences(updatedPrefs);
      setNotificationPrefs(updatedPrefs);
      setShowSmsConsent(false);
      setSmsConsentChecked(false);
      toast.success('SMS notifications enabled');
    } catch (err) {
      console.error('Error enabling SMS notifications:', err);
      toast.error('Failed to enable SMS notifications');
    }
  };

  const handleProfilePictureClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      toast.error('Please select an image file');
      return;
    }

    // Validate file size (5MB max)
    if (file.size > 5 * 1024 * 1024) {
      toast.error('Image size should be less than 5MB');
      return;
    }

    try {
      const response = await updateProfilePicture(file);
      console.log('Profile photo update response:', response);
      
      if (response.data.profile_photo) {
        console.log('New profile photo URL:', response.data.profile_photo);
        setProfile(prev => ({
          ...prev,
          profile_photo: response.data.profile_photo
        }));
        
        // Track successful profile picture update
        trackEvent('profile_picture_update', {
          success: true,
          file_size: file.size,
          file_type: file.type
        });
        
        toast.success('Profile picture updated successfully');
      }
    } catch (err) {
      console.error('Error updating profile picture:', err);
      
      // Track failed profile picture update
      trackEvent('profile_picture_update', {
        success: false,
        error: err.message,
        file_size: file.size,
        file_type: file.type
      });
      
      toast.error('Failed to update profile picture');
    }
  };

  const handleEditClick = () => {
    setEditedProfile(profile);
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      // Only update fields that have changed
      const updates = {};
      Object.keys(editedProfile).forEach(key => {
        if (editedProfile[key] !== profile[key]) {
          updates[key] = editedProfile[key];
        }
      });

      if (Object.keys(updates).length > 0) {
        await updateProfile(updates);
        setProfile(editedProfile);
        toast.success('Profile updated successfully');
        trackEvent('profile_update_success', { fields: Object.keys(updates) });
      }
      setIsEditMode(false);
    } catch (error) {
      console.error('Profile update error:', error);
      toast.error(error.response?.data?.error || 'Failed to update profile');
      trackEvent('profile_update_error', { error: error.message });
    }
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedProfile({});
  };

  const handleInputChange = (field, value) => {
    setEditedProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const renderError = () => (
    <div className="flex flex-col items-center justify-center p-8 bg-red-50 rounded-lg">
      <FiAlertCircle className="text-red-500 text-4xl mb-4" />
      <h3 className="text-lg font-semibold text-red-700 mb-2">Error Loading Settings</h3>
      <p className="text-red-600 mb-4">{error}</p>
      <button
        onClick={() => {
          setRetryCount(0);
          fetchData();
        }}
        className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
      >
        Try Again
      </button>
    </div>
  );

  const renderSmsConsentModal = () => {
    if (!showSmsConsent) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <div className="mt-3 text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">SMS Notifications Consent</h3>
            <div className="mt-2 px-7 py-3">
              <p className="text-sm text-gray-500 text-left">
                By enabling SMS notifications, you agree to receive text messages from Goffer AI. Message and data rates may apply. Message frequency varies. Reply STOP to opt-out at any time.
              </p>
              <div className="mt-4 text-left">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-blue-600"
                    checked={smsConsentChecked}
                    onChange={(e) => setSmsConsentChecked(e.target.checked)}
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    I agree to receive SMS notifications and accept the terms above
                  </span>
                </label>
              </div>
            </div>
            <div className="items-center px-4 py-3">
              <button
                onClick={handleSmsConsentSubmit}
                className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 mr-2"
              >
                Enable SMS
              </button>
              <button
                onClick={() => {
                  setShowSmsConsent(false);
                  setSmsConsentChecked(false);
                }}
                className="px-4 py-2 bg-gray-100 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <div className="text-center py-4">Loading...</div>;
    }

    if (error) {
      return renderError();
    }

    return (
      <div className="space-y-6">
        {/* Profile Section */}
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium text-gray-900">Profile Settings</h2>
            {!isEditMode ? (
              <button
                onClick={handleEditClick}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Edit Profile
              </button>
            ) : (
              <div className="space-x-2">
                <button
                  onClick={handleSaveClick}
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  Save Changes
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          
          {/* Profile Photo */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700">Profile Photo</label>
            <div className="mt-2 flex items-center space-x-5">
              <div className="w-24 h-24 rounded-full bg-gray-300 flex items-center justify-center overflow-hidden">
                {profile?.profile_photo ? (
                  <img 
                    src={profile.profile_photo} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                )}
              </div>
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={handleFileChange}
              />
              <button
                type="button"
                onClick={handleProfilePictureClick}
                className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Change
              </button>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Recommended: Square image, max 5MB
            </p>
          </div>

          {/* Name Fields */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              {isEditMode ? (
                <input
                  type="text"
                  value={editedProfile.first_name || ''}
                  onChange={(e) => handleInputChange('first_name', e.target.value)}
                  className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              ) : (
                <div className="mt-1 text-sm text-gray-900">{profile.first_name}</div>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              {isEditMode ? (
                <input
                  type="text"
                  value={editedProfile.last_name || ''}
                  onChange={(e) => handleInputChange('last_name', e.target.value)}
                  className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              ) : (
                <div className="mt-1 text-sm text-gray-900">{profile.last_name}</div>
              )}
            </div>
          </div>

          {/* Email Fields */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <div className="mt-1 text-sm text-gray-900">{profile.email}</div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Goffer Email</label>
              <div className="mt-1 text-sm text-gray-900">{profile.goffer_email}</div>
            </div>
          </div>

          {/* Phone Number */}
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            {isEditMode ? (
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  className="flex-1 min-w-0 block w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="+1 (555) 555-5555"
                  value={editedProfile.mobile_phone || ''}
                  onChange={(e) => handleInputChange('mobile_phone', e.target.value)}
                />
              </div>
            ) : (
              <div className="mt-1 text-sm text-gray-900">{profile.mobile_phone || 'Not set'}</div>
            )}
            <p className="mt-1 text-sm text-gray-500">
              Used for SMS notifications and important alerts
            </p>
          </div>

          {/* Notification Preferences */}
          <div className="mt-6">
            <h2 className="text-xl font-semibold text-gray-900">Notification Preferences</h2>
            
            {/* Notification Channels */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Notification Channels</h3>
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <div>
                    <label className="text-sm font-medium text-gray-700">Email Notifications</label>
                    <p className="text-sm text-gray-500">Receive notifications via email</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleNotificationChange('emailNotifications', !notificationPrefs.emailNotifications)}
                    className={`${
                      notificationPrefs.emailNotifications ? 'bg-blue-500' : 'bg-gray-200'
                    } relative inline-flex items-center w-10 h-5 rounded-full transition-colors duration-200 ease-in-out p-0`}
                  >
                    <span
                      className={`absolute inline-block w-4 h-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out ${
                        notificationPrefs.emailNotifications ? 'translate-x-[22px]' : 'translate-x-[2px]'
                      }`}
                    />
                  </button>
                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <label className="text-sm font-medium text-gray-700">Push Notifications</label>
                    <p className="text-sm text-gray-500">Receive in-app notifications</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleNotificationChange('pushNotifications', !notificationPrefs.pushNotifications)}
                    className={`${
                      notificationPrefs.pushNotifications ? 'bg-blue-500' : 'bg-gray-200'
                    } relative inline-flex items-center w-10 h-5 rounded-full transition-colors duration-200 ease-in-out p-0`}
                  >
                    <span
                      className={`absolute inline-block w-4 h-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out ${
                        notificationPrefs.pushNotifications ? 'translate-x-[22px]' : 'translate-x-[2px]'
                      }`}
                    />
                  </button>
                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <label className="text-sm font-medium text-gray-700">SMS Notifications</label>
                    <p className="text-sm text-gray-500">
                      Receive notifications via text message
                      {notificationPrefs.smsOptIn && notificationPrefs.smsOptInDate && (
                        <span className="block text-xs text-gray-400">
                          Opted in on {new Date(notificationPrefs.smsOptInDate).toLocaleDateString()}
                        </span>
                      )}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleNotificationChange('smsNotifications', !notificationPrefs.smsNotifications)}
                    className={`${
                      notificationPrefs.smsNotifications ? 'bg-blue-500' : 'bg-gray-200'
                    } relative inline-flex items-center w-10 h-5 rounded-full transition-colors duration-200 ease-in-out p-0`}
                  >
                    <span
                      className={`absolute inline-block w-4 h-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out ${
                        notificationPrefs.smsNotifications ? 'translate-x-[22px]' : 'translate-x-[2px]'
                      }`}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center w-full">
      <div className="max-w-3xl w-full px-4 py-8 space-y-8">
        {renderContent()}
        {renderSmsConsentModal()}
      </div>
    </div>
  );
}

export default Settings;