import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as endpoints from '../services/endpoints'; 
import { useGoogleLogin } from '@react-oauth/google';
import { trackEvent } from '../utils/analytics';
import { Helmet } from 'react-helmet-async';

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(''); // Add this state for error handling
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationMode, setIsVerificationMode] = useState(false);

  // Check for verification parameter in URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const verificationMode = searchParams.get('google_verification') === 'true';
    setIsVerificationMode(verificationMode);
    
    if (verificationMode) {
      console.log('Google verification mode activated');
    }
  }, [location]);

  // Check for Google auth callback
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const googleAuthError = searchParams.get('error');
    
    if (code) {
      // Handle the Google auth callback
      console.log('Received Google auth code, processing...');
      // You would process this code on your backend
      // This is just a placeholder for now
    }
    
    if (googleAuthError) {
      setError(`Google authentication error: ${googleAuthError}`);
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear any previous errors
    const formData = new FormData(event.target);
    const credentials = {
      email: formData.get('email'),
      password: formData.get('password')
    };

    try {
      const response = await endpoints.login(credentials);
      if (response.data?.access_token) {
        trackEvent('login_success', { method: 'email' });
        localStorage.setItem('jwt_token', response.data.access_token);
        navigate('/dashboard');
      } else {
        setError('Invalid response from server');
      }
    } catch (error) {
      trackEvent('login_error', { 
        error_type: error.response?.data?.error || 'unknown_error'
      });
      console.error('Login error:', error.response?.data?.error || error.message);
      setError(error.response?.data?.error || 'An error occurred during login');
    } finally {
      setIsLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        console.log('Google login successful, fetching user info...');
        // First, get user info from Google
        const userInfoResponse = await fetch(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${codeResponse.access_token}` },
          }
        );
        const userInfo = await userInfoResponse.json();
        
        console.log('User info retrieved, sending to backend...');
        // Now send the ID token to your backend using your API service
        const response = await endpoints.googleLogin({
          token: codeResponse.access_token,
          userInfo: userInfo,
          isVerificationMode: isVerificationMode // Pass verification mode to backend
        });

        if (response.data?.access_token) {
          trackEvent('login_success', { method: 'google' });
          localStorage.setItem('jwt_token', response.data.access_token);
          navigate('/dashboard');
        } else {
          setError('Invalid response from server');
        }
      } catch (error) {
        trackEvent('login_error', { 
          method: 'google',
          error_type: error.response?.data?.error || 'unknown_error'
        });
        console.error('Google login error:', error);
        setError(error.response?.data?.message || 'Google login failed');
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setError('Google login failed. Please try again.');
    },
    // Use implicit flow instead of authorization code flow
    flow: 'implicit',
    // Request additional scopes when in verification mode
    scope: isVerificationMode ? 
      'email profile https://www.googleapis.com/auth/calendar.readonly' : 
      'email profile',
    // Use redirect mode instead of popup to avoid COOP issues
    ux_mode: 'redirect',
    // Specify the redirect URI (should match what's configured in Google Console)
    redirect_uri: window.location.origin + '/login',
    // Add state parameter for security
    state: 'google_auth_' + Math.random().toString(36).substring(2, 15),
    // Disable auto_select to ensure the user explicitly chooses an account
    auto_select: false
  });

  return (
    <>
      <Helmet>
        <title>Login - Goffer.AI | Access Your Account</title>
        <meta name="description" content="Log in to your Goffer.AI account to access your documents, manage alerts, and use our AI-powered document assistant." />
        <meta name="robots" content="noindex, nofollow" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Login - Goffer.AI | Access Your Account" />
        <meta property="og:description" content="Access your Goffer.AI account to manage your documents and alerts." />
        <meta property="og:image" content="https://goffer.ai/login-og.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login - Goffer.AI | Access Your Account" />
        <meta name="twitter:description" content="Access your Goffer.AI account to manage your documents and alerts." />
        <meta name="twitter:image" content="https://goffer.ai/login-twitter.jpg" />
      </Helmet>

      <div className="flex min-h-screen items-center justify-center">
        <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
          <h2 className="text-[#1980e6] text-base font-medium text-center">Log in to Goffer AI</h2>
          <p className="text-[#111418] text-sm text-center mt-1 mb-6">
            Access the power of AI to manage your documents effortlessly.
          </p>

          {isVerificationMode && (
            <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4">
              Google verification mode is active. This login will request additional permissions.
            </div>
          )}

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              {error}
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="text-sm text-[#111418]">Email</label>
              <input 
                name="email"
                type="email" 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
                required
                data-testid="email-input"
              />
            </div>
            
            <div>
              <label className="text-sm text-[#111418]">Password</label>
              <input 
                name="password"
                type="password" 
                className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
                required
                data-testid="password-input"
              />
            </div>

            <div className="flex justify-end">
              <a 
                href="/forgot-password" 
                className="text-sm text-[#1980e6] hover:underline"
              >
                Forgot password?
              </a>
            </div>

            <button 
              type="submit" 
              className="w-full bg-[#1980e6] text-white rounded-lg py-2"
              data-testid="login-submit-button"
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Log in'}
            </button>

            <div className="relative mt-4 mb-4">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">or</span>
              </div>
            </div>

            <button
              onClick={() => googleLogin()}
              className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
            >
              <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                <path
                  d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                  fill="#EA4335"
                />
                <path
                  d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                  fill="#4285F4"
                />
                <path
                  d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                  fill="#34A853"
                />
              </svg>
              <span className="text-sm font-medium">Continue with Google</span>
            </button>

            <div className="w-full">
              {/* Facebook login button removed */}
            </div>

            <p className="text-center text-sm">
              Don't have an account? <a href="/register" className="text-[#1980e6]">Register here</a>
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
