export const getProfilePhotoUrl = async (permanentUrl) => {
    if (!permanentUrl) return null;
    try {
        console.log('[DEBUG] Getting presigned URL for:', permanentUrl);
        console.log('[DEBUG] Current origin:', window.location.origin);
        console.log('[DEBUG] API endpoint:', `/api/user/profile/photo/presigned?url=${encodeURIComponent(permanentUrl)}`);
        
        const token = localStorage.getItem('jwt_token');
        const response = await fetch(`/api/user/profile/photo/presigned?url=${encodeURIComponent(permanentUrl)}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }
        });
        
        if (!response.ok) {
            console.error('[DEBUG] Failed to get presigned URL:', {
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                headers: Object.fromEntries([...response.headers.entries()]),
                token: token ? 'Token exists' : 'No token found'
            });
            
            // Try to get response text for more details
            try {
                const errorText = await response.text();
                console.error('[DEBUG] Error response body:', errorText);
            } catch (textError) {
                console.error('[DEBUG] Could not read error response body:', textError);
            }
            
            return permanentUrl;
        }
        
        const data = await response.json();
        console.log('[DEBUG] Presigned URL response:', data);
        
        if (!data.presigned_url) {
            console.error('[DEBUG] No presigned URL in response:', data);
            return permanentUrl;
        }
        
        return data.presigned_url;
    } catch (error) {
        console.error('[DEBUG] Error getting presigned URL:', error);
        console.error('[DEBUG] Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack,
            url: permanentUrl
        });
        return permanentUrl;
    }
}; 