import React from 'react';
import { Helmet } from 'react-helmet-async';

function Features() {
  return (
    <>
      <Helmet>
        <title>Features - Goffer.AI | Smart Document Processing & Management</title>
        <meta name="description" content="Discover Goffer.AI's powerful features: AI-powered document processing, smart date extraction, automated reminders, and intelligent document organization. Make document management effortless." />
        <meta name="keywords" content="document processing, AI features, smart reminders, document management, OCR, PDF processing, date extraction, document organization" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Features - Goffer.AI | Smart Document Processing & Management" />
        <meta property="og:description" content="Explore how Goffer.AI's smart features can transform your document management with AI-powered processing and intelligent organization." />
        <meta property="og:image" content="https://goffer.ai/features-og.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Features - Goffer.AI | Smart Document Processing & Management" />
        <meta name="twitter:description" content="Explore how Goffer.AI's smart features can transform your document management with AI-powered processing and intelligent organization." />
        <meta name="twitter:image" content="https://goffer.ai/features-twitter.jpg" />
        
        {/* Additional SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai/features" />
      </Helmet>

      <div className="relative flex min-h-screen flex-col bg-white">
        <div className="flex flex-1 justify-center">
          <div className="w-full max-w-[1200px] px-4 md:px-10 lg:px-20 py-8 md:py-12">
            {/* Header Section */}
            <div className="flex flex-col gap-6 mb-12">
              <h1 className="text-[#111418] text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">
                Features
              </h1>
              <p className="text-[#637588] text-base md:text-lg max-w-[800px]">
                Discover how Goffer AI can transform your document processing and management
              </p>
            </div>

            {/* Features Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {/* Feature Card - Document Processing */}
              <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4]">
                <h2 className="text-[#111418] text-xl font-bold leading-tight">Upload Documents</h2>
                <p className="text-[#637588] text-base">
                  Easily upload documents directly to Goffer AI for quick processing. Whether it's through your desktop, mobile device, or cloud storage, you can seamlessly integrate your files into the platform.
                </p>
                <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                  <p className="text-[#111418] font-medium mb-2">How It Works:</p>
                  <ul className="text-[#637588] list-disc list-inside space-y-2">
                    <li>Upload documents in various formats</li>
                    <li>Automatic content analysis</li>
                    <li>Smart organization and tagging</li>
                  </ul>
                </div>
              </div>

              {/* Feature Card - AI Analysis */}
              <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4]">
                <h2 className="text-[#111418] text-xl font-bold leading-tight">AI-Powered Analysis</h2>
                <p className="text-[#637588] text-base">
                  Let our advanced AI analyze your documents to extract key information, identify patterns, and provide valuable insights automatically.
                </p>
                <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                  <p className="text-[#111418] font-medium mb-2">How It Works:</p>
                  <ul className="text-[#637588] list-disc list-inside space-y-2">
                    <li>Automatic content analysis</li>
                    <li>Extraction of key information</li>
                    <li>Pattern identification</li>
                  </ul>
                </div>
              </div>

              {/* Feature Card - Chat Assistant */}
              <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4]">
                <h2 className="text-[#111418] text-xl font-bold leading-tight">Chat Assistant</h2>
                <p className="text-[#637588] text-base">
                  Chat with your AI assistant directly from the platform to get summaries, create tasks, or ask questions about your documents.
                </p>
                <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                  <p className="text-[#111418] font-medium mb-2">How It Works:</p>
                  <ul className="text-[#637588] list-disc list-inside space-y-2">
                    <li>Start a chat session with your AI assistant</li>
                    <li>Ask questions about your documents</li>
                    <li>Request tasks to be created</li>
                  </ul>
                </div>
              </div>

              {/* Feature Card - Web Page Analysis */}
              <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4]">
                <h2 className="text-[#111418] text-xl font-bold leading-tight">Web Page Analysis</h2>
                <p className="text-[#637588] text-base">
                  Simply provide a URL, and Goffer will analyze and index the webpage content, allowing you to ask questions and extract insights from any online resource.
                </p>
                <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                  <p className="text-[#111418] font-medium mb-2">Key Features:</p>
                  <ul className="text-[#637588] list-disc list-inside space-y-2">
                    <li>One-click webpage analysis</li>
                    <li>Automatic content extraction</li>
                    <li>Full-text search capabilities</li>
                    <li>Cross-reference with other documents</li>
                  </ul>
                </div>
              </div>

              {/* Feature Card - Google Calendar Integration */}
              <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4] relative overflow-hidden">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Google Calendar Integration</h2>
                  <div className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-sm font-medium">
                    Coming Soon
                  </div>
                </div>
                <p className="text-[#637588] text-base">
                  Seamlessly sync important dates and deadlines from your documents to Google Calendar. Stay on top of your schedule with automated event creation and smart reminders.
                </p>
                <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                  <p className="text-[#111418] font-medium mb-2">Key Features:</p>
                  <ul className="text-[#637588] list-disc list-inside space-y-2">
                    <li>Automatic event creation from documents</li>
                    <li>Customizable reminder schedules</li>
                    <li>Direct document links in calendar events</li>
                    <li>Smart deadline detection</li>
                  </ul>
                </div>
              </div>

              {/* Feature Card - Google Drive Integration */}
              <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4] relative overflow-hidden">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Google Drive Integration</h2>
                  <div className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-sm font-medium">
                    Coming Soon
                  </div>
                </div>
                <p className="text-[#637588] text-base">
                  Analyze and extract insights from your Google Drive documents directly. Connect your Drive account to seamlessly process and understand your stored documents.
                </p>
                <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                  <p className="text-[#111418] font-medium mb-2">Key Features:</p>
                  <ul className="text-[#637588] list-disc list-inside space-y-2">
                    <li>Direct Google Drive connection</li>
                    <li>Automatic document analysis</li>
                    <li>Real-time document sync</li>
                    <li>Smart folder organization</li>
                  </ul>
                </div>
              </div>

              {/* Feature Card - Gmail Integration */}
              <div className="flex flex-col gap-4 p-6 bg-white rounded-xl border border-[#f0f2f4] relative overflow-hidden">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Gmail Integration</h2>
                  <div className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-sm font-medium">
                    Coming Soon
                  </div>
                </div>
                <p className="text-[#637588] text-base">
                  Process and analyze your incoming Gmail messages automatically. Extract important information, dates, and action items from your emails effortlessly.
                </p>
                <div className="bg-[#f0f2f4] rounded-lg p-6 mt-auto">
                  <p className="text-[#111418] font-medium mb-2">Key Features:</p>
                  <ul className="text-[#637588] list-disc list-inside space-y-2">
                    <li>Automatic email analysis</li>
                    <li>Important date extraction</li>
                    <li>Action item detection</li>
                    <li>Smart email categorization</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
