import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet-async';
import { useParams, Link } from 'react-router-dom';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    // This would typically be an API call to fetch the blog post data
    // For now, we'll use a simple mapping of slugs to content
    const posts = {
      'office-support-release': {
        title: "Introducing Full Microsoft Office Support",
        date: "February 10, 2025",
        description: "We're excited to announce full support for Microsoft Office documents! Upload and process Word documents, Excel spreadsheets, and PowerPoint presentations with enhanced mobile support and improved document handling.",
        keywords: "microsoft office, word, excel, powerpoint, document processing, mobile support",
        content: `
# Introducing Full Microsoft Office Support

We're thrilled to announce that Goffer.AI now fully supports Microsoft Office documents! This major update brings powerful capabilities for handling Word documents, Excel spreadsheets, and PowerPoint presentations.

## Microsoft Word Support
- Full text extraction and processing
- Formatting preservation
- Track changes support
- Comments and annotations

## Excel Integration
- Data extraction from spreadsheets
- Formula understanding
- Multi-sheet support
- Chart recognition

## PowerPoint Features
- Slide content extraction
- Image and diagram processing
- Speaker notes support
- Presentation structure analysis

## Enhanced Mobile Experience
- View Office documents on any device
- Real-time collaboration
- Mobile-optimized interface
- Quick actions and shortcuts

## Future Development
- Advanced template matching
- More automation features
- Enhanced collaboration tools
- Deeper integration with Microsoft 365

Stay tuned for more updates!
        `
      },
      'january-2025-updates': {
        title: "Enhanced Document Processing & Alert Generation",
        date: "January 26, 2025",
        description: "Your personal assistant just got smarter! Now with enhanced abilities to read text from images, better understand PDFs, and manage your important dates more intelligently.",
        keywords: "document processing, OCR, PDF reading, smart alerts, date detection, mobile notifications, personal assistant, AI assistant",
        content: `
# Enhanced Document Processing & Alert Generation

We're excited to announce significant improvements to our personal assistant system, making it even smarter at understanding your documents and managing your important dates.

## Document Understanding

### Advanced Format Support
- Image Processing
  - Text extraction from photos and scans
  - Automatic rotation and skew correction
  - Multi-language support
  - Complex layout analysis

- PDF Processing
  - Information extraction from contracts and reports
  - Multi-page document support
  - Scanned document optimization
  - Digital document parsing

### Text Analysis
- Long document processing
  - Context preservation
  - Structure recognition
  - Enhanced information extraction

## Alert Management

### Intelligent Date Detection
- Comprehensive source coverage
  - Email processing
  - PDF attachment analysis
  - Image text extraction
  - Context preservation

### Alert Organization
- Priority-based scheduling
- Date range filtering
- Duplicate detection
- Contextual information retention

## Performance Improvements

### System Optimization
- Response time reduction
- Resource utilization
- System-wide performance gains

### Mobile Enhancements
- Interface optimization
- Notification handling
- Alert management
- Performance optimization

## Development Roadmap
- Advanced document processing
- Enhanced alert prioritization
- Extended format support
- Mobile capabilities

Stay tuned for more updates!
        `
      },
      'enhanced-notification-system': {
        title: "Enhanced Notification System: Smarter Document Management",
        date: "January 21, 2025",
        description: "We're excited to announce significant improvements to our notification system, making document management smarter and more efficient than ever!",
        keywords: "notifications, document management, updates, features, smart alerts",
        content: `
# Enhanced Notification System: Smarter Document Management

We're introducing our enhanced notification system, designed to make your document management experience smarter and more efficient.

## Intelligent Notifications

### Context-Aware Processing
- Priority assignment algorithms
- Contextual information analysis
- Document relationship mapping
- Notification clustering

### Delivery Optimization
- Channel configuration
- Schedule management
- Rule-based routing
- Batch processing

## Mobile Integration

### Interface Improvements
- Notification management
- Action optimization
- System navigation
- Response handling

### System Architecture
- Real-time delivery
- Status monitoring
- Background operations
- Offline capabilities

## System Roadmap
- Advanced customization
- Enhanced filtering
- Service integration
- Mobile optimization

Stay tuned for more updates!
        `
      }
    };

    const postData = posts[slug];
    if (postData) {
      setPost(postData);
    } else {
      // Handle 404 case
      setPost({
        title: "Blog Post Not Found",
        date: "",
        description: "The requested blog post could not be found.",
        keywords: "",
        content: "# 404: Blog Post Not Found\n\nSorry, we couldn't find the blog post you're looking for."
      });
    }
  }, [slug]);

  if (!post) {
    return <div className="max-w-4xl mx-auto px-4 py-8">Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{post.title} | Goffer.AI Blog</title>
        <meta name="description" content={post.description} />
        <meta name="keywords" content={post.keywords} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="article:published_time" content="2025-01-26" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.description} />
        
        {/* Additional SEO */}
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href={`https://goffer.ai/blog/${slug}`} />
      </Helmet>
      <div className="bg-white min-h-screen">
        {/* Navigation */}
        <nav className="border-b border-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <Link 
              to="/blog" 
              className="inline-flex items-center h-16 text-sm text-gray-500 hover:text-gray-900"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to Blog
            </Link>
          </div>
        </nav>

        {/* Article Header */}
        <header className="pt-16 pb-12 border-b border-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl">
              <div className="text-base text-gray-600 mb-4">
                {post.date}
              </div>
              <h1 className="text-5xl font-medium tracking-tight text-gray-900 mb-8">
                {post.title}
              </h1>
              <p className="text-2xl text-gray-600 leading-relaxed">
                {post.description}
              </p>
            </div>
          </div>
        </header>

        {/* Article Content */}
        <main className="py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl">
              <article className="prose prose-lg prose-slate mx-auto">
                <div className="prose-headings:font-medium prose-headings:text-gray-900 prose-headings:tracking-tight
                              prose-h2:text-3xl prose-h2:mt-16 prose-h2:mb-6
                              prose-h3:text-2xl prose-h3:mt-12 prose-h3:mb-4
                              prose-p:text-lg prose-p:text-gray-600 prose-p:leading-relaxed prose-p:my-6
                              prose-a:text-blue-600 prose-a:no-underline hover:prose-a:underline
                              prose-strong:text-gray-900 prose-strong:font-medium
                              prose-ul:my-6 prose-li:text-gray-600 prose-li:my-2
                              prose-pre:bg-gray-50 prose-pre:border prose-pre:border-gray-100 prose-pre:p-4
                              prose-code:text-gray-800 prose-code:bg-gray-50 prose-code:px-1 prose-code:rounded-sm
                              prose-img:rounded-lg prose-img:shadow-lg">
                  <ReactMarkdown>{post.content}</ReactMarkdown>
                </div>

                {/* Article Footer */}
                <footer className="mt-16 pt-8 border-t border-gray-100">
                  <div className="flex flex-wrap gap-2">
                    {post.keywords.split(',').map((keyword, index) => (
                      <span 
                        key={index}
                        className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full"
                      >
                        {keyword.trim()}
                      </span>
                    ))}
                  </div>
                </footer>
              </article>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default BlogPost; 