import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileLayout from '../layout/MobileLayout';
import ChatInputBar from '../shared/ChatInputBar';
import { createChat, getSuggestedQuestions } from '../../../services/endpoints';
import { processMessage } from '../../../utils/messageHandlers';
import '../../../styles/mobile/home.scss';

// MobileHome component handles the main home screen functionality for mobile devices
// It manages navigation, loading states, mobile menu visibility, and error handling
const MobileHome = () => {
  // Hook for programmatic navigation between routes
  const navigate = useNavigate();

  // State to control mobile menu visibility
  // Used to show/hide the mobile navigation menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Loading state to show activity indicators during async operations
  // Used when submitting messages or processing files
  const [isLoading, setIsLoading] = useState(false);

  // Error state to handle and display any error messages
  // Note: Currently only the setter is destructured, consider using error state if needed
  const [error, setError] = useState(null);

  const [suggestedQuestions, setSuggestedQuestions] = useState({
    "Time-Based": [],
    "Action-Based": [],
    "Domain-Based": []
  });

  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  useEffect(() => {
    const fetchSuggestedQuestions = async () => {
      try {
        setIsLoadingQuestions(true);
        const response = await getSuggestedQuestions();
        if (response.data.success) {
          setSuggestedQuestions(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching suggested questions:', error);
        // Fall back to default templates if there's an error
        setSuggestedQuestions({
          "Time-Based": [
            "When was this document created?",
            "What are the key deadlines mentioned?",
            "How long is this agreement valid for?"
          ],
          "Action-Based": [
            "What steps are required for compliance?",
            "How should I proceed with this process?",
            "What actions need to be taken?"
          ],
          "Domain-Based": [
            "What technical terms are used here?",
            "Who are the key stakeholders involved?",
            "What industry standards are referenced?"
          ]
        });
      } finally {
        setIsLoadingQuestions(false);
      }
    };

    fetchSuggestedQuestions();
  }, []);

  /**
   * Handles the submission of user messages and file uploads
   * @param {Object} messageData - Contains the message content and any attached files
   * @returns {Promise<void>}
   */
  const handleSubmit = async (messageData) => {
    // Debug log to mark the entry point of the submission handler
    console.log('=== MobileHome handleSubmit ===');
    console.log('Received message data:', messageData);

    // Clear any previous errors
    setError(null);

    try {
      // Enable loading state to show user that processing is happening
      setIsLoading(true);

      // Process the message using the messageHandlers utility
      // This handles both text content and file uploads
      // Returns an object with { message, files }
      // - message: contains processed content, type, and file metadata
      // - files: array of processed file objects ready for upload
      const { message, files } = await processMessage(messageData);
      console.log('Processed message:', message);
      console.log('Processed files:', files);

      // Prepare the payload for chat creation
      // - content: the actual text message
      // - files: array of processed files to be attached to the chat
      const payload = {
        content: message.content,
        files
      };
      console.log('Sending chat creation payload:', payload);
      
      // Make API call to create a new chat session
      // Returns chat metadata and AI's initial response
      const result = await createChat(payload);
      console.log('Chat creation result:', result);

      if (!result || !result.chat) {
        throw new Error('Invalid response from server');
      }

      // Navigate to the chat view with the conversation context
      // Using React Router's navigate function with state management
      navigate(`/m/chat/${result.chat.id}`, {
        state: {
          // Set up initial message in chat history
          initialMessage: [{
            sender: 'You', // Mark message as user-generated
            content: message.content, // Original message content
            type: message.type, // Message type (text, file, etc.)
            file: message.file // Any associated file data
          }],
          initialResponse: result.initialResponse // AI's first response
        }
      });
    } catch (error) {
      // Log any errors during the process
      console.error('Error creating chat:', error);
      // Update error state to potentially show user feedback
      setError(error.message || 'An error occurred while processing your request');
      
      // Show error in UI
      if (error.response?.status === 400) {
        setError('File upload failed. Please check file size and type.');
      }
    } finally {
      // Always disable loading state when done, regardless of success/failure
      setIsLoading(false);
    }
  };

  return (
    <MobileLayout 
      onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      title="Goffer AI"
    >
      <div className="mobile-home">
        {/* Add error display */}
        {error && (
          <div className="error-banner">
            <p>{error}</p>
            <button onClick={() => setError(null)}>Dismiss</button>
          </div>
        )}

        {/* Background Logo */}
        <div className="background-logo">
          <img src="/logo512.png" alt="Goffer AI" />
        </div>

        {/* Scrolling Templates */}
        <div className="templates-scroll">
          {isLoadingQuestions ? (
            <div className="loading-questions">
              <i className="fas fa-spinner fa-spin"></i> Loading suggested questions...
            </div>
          ) : (
            Object.entries(suggestedQuestions).map(([title, examples], index) => (
              <div key={index} className="template-card">
                <h3>{title}</h3>
                <ul>
                  {examples.map((example, i) => (
                    <li key={i} onClick={() => handleSubmit({ content: example })}>{example}</li>
                  ))}
                </ul>
              </div>
            ))
          )}
        </div>

        <ChatInputBar 
          onSubmit={handleSubmit} 
          isLoading={isLoading}
          error={error}
        />
      </div>
    </MobileLayout>
  );
};

export default MobileHome; 