import React, { useState, useRef, useEffect } from 'react';
import LeftMenu from '../LeftMenu';
import AuthenticatedNavbar from '../AuthenticatedNavbar';
import FileSearch from '../FileSearch';
import Footer from '../Footer';
import ChatSidebar from '../ChatSidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import { useChatContext } from '../../contexts/ChatContext';

function DashboardLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFileSearchOpen, setIsFileSearchOpen] = useState(false);
  const [isChatSidebarOpen, setIsChatSidebarOpen] = useState(false);
  const fileSearchRef = useRef();
  const { handleChatSelect } = useChatContext();

  useEffect(() => {
    console.log('DashboardLayout - Current state:', {
      path: location.pathname,
      isChatSidebarOpen,
      isFileSearchOpen
    });
  }, [location.pathname, isChatSidebarOpen, isFileSearchOpen]);

  const handleFileSearchClick = () => {
    console.log('DashboardLayout - File search clicked');
    setIsFileSearchOpen(!isFileSearchOpen);
  };

  const handleChatClick = () => {
    console.log('DashboardLayout - Chat sidebar toggled');
    setIsChatSidebarOpen(!isChatSidebarOpen);
  };

  const handleNotificationsClick = () => {
    console.log('DashboardLayout - Notifications clicked');
  };

  const handleChatSelectAndNavigate = (chat) => {
    console.log('DashboardLayout - Chat selected:', chat);
    console.log('DashboardLayout - Current path:', location.pathname);
    
    handleChatSelect(chat);
    
    if (location.pathname !== '/dashboard') {
      console.log('DashboardLayout - Navigating to dashboard from:', location.pathname);
      navigate('/dashboard');
    }
    
    console.log('DashboardLayout - Closing chat sidebar');
    setIsChatSidebarOpen(false);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="flex flex-1">
        <LeftMenu 
          onFileSearchClick={handleFileSearchClick}  // Pass the same handler
          onChatHistoryClick={handleChatClick}
          onRemindersClick={() => navigate('/reminders')}
          onSettingsClick={() => navigate('/settings')}
          isFileSearchActive={isFileSearchOpen}  // Add this prop
        />
        <div className="flex-1 flex flex-col">
          <AuthenticatedNavbar
            onFileSearchClick={handleFileSearchClick}  // Pass the same handler
            onChatClick={handleChatClick}
            onNotificationsClick={handleNotificationsClick}
            showSearchBar={false}
          />
          <div className="flex-1 overflow-auto">
            <FileSearch 
              ref={fileSearchRef}
              isOpen={isFileSearchOpen}
              onClose={() => setIsFileSearchOpen(false)}
              style={{ display: isFileSearchOpen ? 'block' : 'none' }}
            />
            {!isFileSearchOpen && children}
          </div>
        </div>
      </div>
      <ChatSidebar 
        isOpen={isChatSidebarOpen} 
        onClose={() => setIsChatSidebarOpen(false)} 
        onChatSelect={handleChatSelectAndNavigate}
        onFileSearchClose={() => setIsFileSearchOpen(false)}
      />
      <Footer />
    </div>
  );
}

export default DashboardLayout; 