import React from 'react';
import { Helmet } from 'react-helmet-async';

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Goffer.AI | Data Protection & Security</title>
        <meta name="description" content="Learn about Goffer.AI's commitment to protecting your privacy and data security. Our comprehensive privacy policy details how we handle and safeguard your information." />
        <meta name="keywords" content="privacy policy, data protection, GDPR compliance, data security, document privacy, user privacy, information security" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Privacy Policy - Goffer.AI | Data Protection & Security" />
        <meta property="og:description" content="Understand how Goffer.AI protects your privacy and ensures the security of your documents and personal information." />
        <meta property="og:image" content="https://goffer.ai/privacy-og.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Goffer.AI | Data Protection & Security" />
        <meta name="twitter:description" content="Understand how Goffer.AI protects your privacy and ensures the security of your documents and personal information." />
        <meta name="twitter:image" content="https://goffer.ai/privacy-twitter.jpg" />
        
        {/* Additional SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai/privacy" />
      </Helmet>

      <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden" style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}>
        <div className="layout-container flex h-full grow flex-col">
          
          {/* Main Content */}
          <div className="px-40 flex flex-1 justify-center py-5">
            <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
              <div className="flex flex-col gap-10 px-4 py-10">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[#111418] text-[32px] font-bold leading-tight tracking-[-0.033em]">Privacy Policy</h1>
                  <p className="text-[#637588] text-base leading-normal">
                    Last updated: {new Date().toLocaleDateString()}
                  </p>
                </div>

                {/* Introduction */}
                <div className="flex flex-col gap-4">
                  <p className="text-[#111418] text-base leading-normal">
                    At Goffer AI, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your information when you use our services. Goffer AI is an independent document management platform that integrates with various third-party services, including Google Workspace, to enhance your document processing experience.
                  </p>
                  <p className="text-[#111418] text-base leading-normal">
                    Important Note: Goffer AI is not affiliated with, endorsed by, or sponsored by Google. We are a third-party application that provides document management services while maintaining strict compliance with Google's API Services User Data Policy.
                  </p>
                </div>

                {/* Key Privacy Commitments */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Our Key Privacy Commitments</h2>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <ul className="list-disc list-inside space-y-4 text-[#111418]">
                      <li>We will never share your data with third parties without your explicit consent</li>
                      <li>We will never use your documents to train our large language models without your explicit consent</li>
                      <li>We will never fine-tune our models on your data without your explicit permission</li>
                      <li>Your data belongs to you, and you maintain full control over it at all times</li>
                    </ul>
                  </div>
                </div>

                {/* Data Collection */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Information We Collect</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We collect and process the following categories of personal data when you use Goffer AI:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Account information (email address, name, profile information)</li>
                    <li>Documents you upload or share with our service</li>
                    <li>Usage data to improve our service performance</li>
                    <li>Communication preferences</li>
                  </ul>
                  
                  <h3 className="text-[#111418] text-lg font-bold leading-tight mt-4">Google User Data</h3>
                  <p className="text-[#637588] text-base leading-normal">
                    When you connect your Google account, we may collect:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Calendar events and metadata for deadline management</li>
                    <li>Basic Google profile information (name, email)</li>
                    <li>Google Drive document metadata for document processing</li>
                    <li>Gmail metadata for email-related features</li>
                  </ul>
                </div>

                {/* How We Use Your Information */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">How We Use Your Information</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    Your information is used exclusively for:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Providing our document processing and AI assistant services</li>
                    <li>Improving our service performance and user experience</li>
                    <li>Communicating with you about your account and our services</li>
                    <li>Ensuring the security and reliability of our platform</li>
                    <li>Syncing important dates and deadlines with your calendar</li>
                    <li>Processing and analyzing your documents for insights</li>
                  </ul>
                  
                  <div className="bg-[#f0f2f4] rounded-lg p-6 mt-4">
                    <p className="text-[#111418] font-medium mb-2">Important Notice:</p>
                    <ul className="list-disc list-inside space-y-2 text-[#637588]">
                      <li>We do not use your data for advertising purposes</li>
                      <li>We do not sell your data to third parties</li>
                      <li>We do not use your data for credit assessment or lending decisions</li>
                      <li>We do not create public databases from your information</li>
                      <li>We do not use your data to train AI models without explicit consent</li>
                    </ul>
                  </div>
                </div>

                {/* Data Security */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Data Security</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We implement robust security measures to protect your data:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>End-to-end encryption for document storage and transmission</li>
                    <li>Regular security audits and updates</li>
                    <li>Strict access controls and authentication measures</li>
                    <li>Secure data centers with industry-standard protections</li>
                    <li>Regular security training for all personnel with data access</li>
                    <li>Continuous monitoring for unauthorized access attempts</li>
                    <li>Secure backup systems with encryption at rest</li>
                  </ul>

                  <h3 className="text-[#111418] text-lg font-bold leading-tight mt-4">Protection of Sensitive Data</h3>
                  <p className="text-[#637588] text-base leading-normal">
                    For sensitive data, including Google user data, we implement additional protections:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Multi-factor authentication for accessing sensitive data</li>
                    <li>Encryption of data both in transit and at rest using industry-standard protocols</li>
                    <li>Regular vulnerability assessments and penetration testing</li>
                    <li>Strict access logging and monitoring</li>
                    <li>Immediate notification of any security incidents</li>
                  </ul>
                </div>

                {/* Data Deletion and Retention */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Data Deletion and Retention</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We maintain clear policies for data retention and deletion:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>You can request deletion of your data at any time through your account settings or by contacting our support team</li>
                    <li>Upon receiving a deletion request, we will process it within 30 days</li>
                    <li>After account deletion, we maintain minimal backup data for up to 30 days for disaster recovery purposes</li>
                    <li>Inactive accounts are automatically deleted after 12 months of inactivity</li>
                    <li>Google user data is retained only as long as necessary to provide our services</li>
                  </ul>

                  <h3 className="text-[#111418] text-lg font-bold leading-tight mt-4">How to Request Data Deletion</h3>
                  <p className="text-[#637588] text-base leading-normal">
                    You can request deletion of your data through:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Your account settings page under "Privacy & Data"</li>
                    <li>Emailing our privacy team at support@goffer.ai</li>
                    <li>Submitting a request through our support portal</li>
                    <li>Disconnecting Google integration through your Google Account settings</li>
                  </ul>
                </div>

                {/* Google API Services */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Integration with Google Services</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    Goffer AI integrates with Google Workspace as a third-party application. When you choose to connect your Google account, we access and process your Google user data in accordance with Google's API Services User Data Policy:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>We only request access to the Google user data our service needs to function</li>
                    <li>We only use Google user data for the purposes described to you</li>
                    <li>We do not sell Google user data</li>
                    <li>We provide clear instructions for disconnecting Google account access</li>
                    <li>We maintain internal security measures to prevent unauthorized access to Google user data</li>
                  </ul>
                  <div className="bg-[#f0f2f4] rounded-lg p-6 mt-4">
                    <p className="text-[#111418] font-medium">Branding Clarification:</p>
                    <p className="text-[#637588] text-base leading-normal mt-2">
                      Goffer AI is an independent brand and service. We do not use Google product names, logos, or trademarks in our branding. Any references to Google services are solely to describe our integration capabilities and compliance with Google's policies.
                    </p>
                  </div>
                </div>

                {/* Limited Use Disclosure */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Limited Use Disclosure</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    Goffer AI's use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy, including the Limited Use requirements. Specifically:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>We will not allow humans to read your Google user data unless we have your affirmative agreement, it is necessary for security purposes, or is required by law</li>
                    <li>We will not use Google user data for serving advertisements</li>
                    <li>We will not sell Google user data or use it for any purpose other than providing our service</li>
                    <li>We will not use or transfer Google user data for any other purpose than providing or improving our service features</li>
                  </ul>
                </div>

                {/* Third-Party Services */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Third-Party Services</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We may use third-party services to support our service delivery:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>All third-party services are carefully vetted for security and privacy compliance</li>
                    <li>We only share the minimum necessary data with third-party services</li>
                    <li>Third-party services are contractually obligated to protect your data</li>
                    <li>We maintain an updated list of all third-party services we use</li>
                  </ul>
                </div>

                {/* Your Rights */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Your Rights</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    You have the right to:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Access your personal data</li>
                    <li>Request deletion of your data</li>
                    <li>Export your data</li>
                    <li>Opt-out of any data processing beyond basic service provision</li>
                    <li>Withdraw consent at any time</li>
                  </ul>
                </div>

                {/* Contact Information */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Contact Us</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    If you have any questions about our Privacy Policy or data practices, please contact us at:
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418]">Email: support@goffer.ai</p>
                  </div>
                </div>

                {/* Updates to Privacy Policy */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Updates to This Policy</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;

