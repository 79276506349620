import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Goffer.AI Blog - Document Processing & Microsoft Office Support</title>
        <meta name="description" content="Stay updated with the latest features including Microsoft Office support, document processing, smart alerts, and AI-powered personal assistance from Goffer.AI." />
        <meta name="keywords" content="Microsoft Office, Word, Excel, PowerPoint, document management, smart alerts, AI assistant, personal assistant, document processing, OCR, PDF processing, mobile notifications" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Goffer.AI Blog - Document Processing & Microsoft Office Support" />
        <meta property="og:description" content="Stay updated with the latest features including Microsoft Office support, document processing, smart alerts, and AI-powered personal assistance from Goffer.AI." />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Goffer.AI Blog - Document Processing & Microsoft Office Support" />
        <meta name="twitter:description" content="Stay updated with the latest features including Microsoft Office support, document processing, smart alerts, and AI-powered personal assistance from Goffer.AI." />
        
        {/* Additional SEO */}
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai/blog" />
      </Helmet>
      <div className="bg-white min-h-screen">
        {/* Header */}
        <header className="pt-16 pb-12 border-b border-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl">
              <h1 className="text-5xl font-medium tracking-tight text-gray-900 mb-8">
                Latest Updates
              </h1>
              <p className="text-2xl text-gray-600 leading-relaxed">
                Stay updated with the latest features and improvements from the Goffer.AI team.
              </p>
            </div>
          </div>
        </header>
        
        {/* Blog Posts */}
        <main className="py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl">
              <div className="space-y-16">
                {/* Latest Blog Post - Office Support */}
                <article className="group">
                  <Link to="/blog/office-support-release" className="block">
                    <div className="text-base text-gray-600 mb-4">
                      February 10, 2025
                    </div>
                    <h2 className="text-3xl font-medium text-gray-900 tracking-tight mb-4 group-hover:text-blue-600 transition-colors">
                      Introducing Full Microsoft Office Support
                    </h2>
                    <p className="text-xl text-gray-600 leading-relaxed mb-6">
                      We're excited to announce full support for Microsoft Office documents! Upload and process Word documents, Excel spreadsheets, and PowerPoint presentations with enhanced mobile support and improved document handling.
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">microsoft-office</span>
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">document-processing</span>
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">mobile-support</span>
                    </div>
                  </Link>
                </article>

                {/* Previous Blog Post - January Updates */}
                <article className="group">
                  <Link to="/blog/january-2025-updates" className="block">
                    <div className="text-base text-gray-600 mb-4">
                      January 26, 2025
                    </div>
                    <h2 className="text-3xl font-medium text-gray-900 tracking-tight mb-4 group-hover:text-blue-600 transition-colors">
                      Enhanced Document Processing & Alert Generation
                    </h2>
                    <p className="text-xl text-gray-600 leading-relaxed mb-6">
                      Your personal assistant just got smarter! Now with enhanced abilities to read text from images, better understand PDFs, and manage your important dates more intelligently.
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">smart-assistant</span>
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">document-reading</span>
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">alerts</span>
                    </div>
                  </Link>
                </article>

                {/* Previous Blog Post - Notification System */}
                <article className="group">
                  <Link to="/blog/enhanced-notification-system" className="block">
                    <div className="text-base text-gray-600 mb-4">
                      January 21, 2025
                    </div>
                    <h2 className="text-3xl font-medium text-gray-900 tracking-tight mb-4 group-hover:text-blue-600 transition-colors">
                      Enhanced Notification System: Smarter Document Management
                    </h2>
                    <p className="text-xl text-gray-600 leading-relaxed mb-6">
                      We're excited to announce significant improvements to our notification system, making document management smarter and more efficient than ever!
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">notifications</span>
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">updates</span>
                      <span className="inline-flex items-center px-3 py-1 text-sm text-gray-600 bg-gray-50 rounded-full">features</span>
                    </div>
                  </Link>
                </article>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Blog; 