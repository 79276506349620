import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getNotifications, getFileUrl, updateNotification, getFileContent } from '../services/endpoints';
import LeftMenu from './LeftMenu';
import FileSearch from './FileSearch';
import { useLocation } from 'react-router-dom';
import { trackEvent } from '../utils/analytics';
import { 
  REMINDER_TYPE_DISPLAY, 
  REMINDER_STATUS_DISPLAY, 
  REMINDER_PRIORITY_DISPLAY,
  getDisplayValue 
} from '../utils/enumMappings';
import Papa from 'papaparse';

function RemindersManagement() {
  const location = useLocation();
  const [reminders, setReminders] = useState([]);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFileSearchOpen, setIsFileSearchOpen] = useState(false);
  const [isChatSidebarOpen, setIsChatSidebarOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedReminder, setEditedReminder] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef();
  const [csvData, setCsvData] = useState(null);
  const [csvError, setCsvError] = useState(null);
  const lastReminderElementRef = useCallback(node => {
    if (loadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        const loadMoreReminders = async () => {
          if (loadingMore || !hasMore) return;
          try {
            setLoadingMore(true);
            const response = await getNotifications(page + 1);
            
            const newReminders = response.data.notifications.map(notification => ({
              id: notification.id,
              title: notification.title,
              completed: notification.status === 'COMPLETED',
              date: new Date(notification.created_at).toLocaleDateString(),
              category: notification.type,
              priority: notification.priority,
              status: notification.status,
              isNew: notification.status === 'PENDING',
              original: {
                ...notification,
                file_info: notification.file_info,
                status: notification.status
              }
            }));

            if (newReminders.length === 0) {
              setHasMore(false);
            } else {
              setReminders(prev => [...prev, ...newReminders]);
              setPage(prev => prev + 1);
            }
          } catch (err) {
            console.error('Error fetching more notifications:', err);
          } finally {
            setLoadingMore(false);
          }
        };
        loadMoreReminders();
      }
    });
    if (node) observer.current.observe(node);
  }, [loadingMore, hasMore, page, setLoadingMore, setHasMore, setReminders, setPage]);

  // Constants for dropdowns
  const PRIORITY_OPTIONS = ['HIGH', 'MEDIUM', 'LOW'];
  const STATUS_OPTIONS = ['PENDING', 'ACKNOWLEDGED', 'COMPLETED', 'DISMISSED'];
  const TYPE_OPTIONS = ['PAYMENT_DUE', 'EXPIRATION', 'RENEWAL', 'APPOINTMENT', 'CUSTOM'];

  useEffect(() => {
    const fetchReminders = async () => {
      try {
        console.log('[DEBUG] Fetching reminders, page:', 1);
        console.log('[DEBUG] Current path:', location.pathname);
        console.log('[DEBUG] Current URL:', window.location.href);
        
        setLoading(true);
        setError(null);
        
        const response = await getNotifications(1); // Add page parameter
        
        console.log('[DEBUG] Reminders response:', {
          status: response.status,
          dataExists: !!response.data,
          notificationsExist: !!response.data?.notifications,
          count: response.data?.notifications?.length || 0
        });
        
        const fetchedReminders = response.data.notifications.map(notification => ({
          id: notification.id,
          title: notification.title,
          completed: notification.status === 'COMPLETED',
          date: new Date(notification.created_at).toLocaleDateString(),
          category: notification.type,
          priority: notification.priority,
          status: notification.status,
          isNew: notification.status === 'PENDING',
          original: {
            ...notification,
            file_info: notification.file_info,
            status: notification.status
          }
        }));
        
        console.log('[DEBUG] Processed reminders count:', fetchedReminders.length);
        
        setReminders(fetchedReminders);
        setPage(1);
        setHasMore(fetchedReminders.length === 10); // If we got 10 items, there might be more
      } catch (err) {
        console.error('[DEBUG] Error fetching reminders:', {
          message: err.message,
          status: err.response?.status,
          statusText: err.response?.statusText,
          data: err.response?.data,
          stack: err.stack
        });
        setError('Failed to load reminders. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchReminders();
    
    // Track page view
    trackEvent('page_view', { page_title: 'Reminders Management' });
  }, [location.pathname]);

  useEffect(() => {
    try {
      if (location.state?.selectedNotification) {
        const notification = location.state.selectedNotification;
        setSelectedReminder({
          ...notification,
          completed: notification.status === 'COMPLETED',
          isNew: notification.status === 'PENDING'
        });
      }
    } catch (err) {
      setError(err.message);
      console.error('Error setting selected notification:', err);
    }
  }, [location]);

  const fetchFileUrl = async (fileId) => {
    try {
      const response = await getFileUrl(fileId);
      setFileUrl(response.url);
    } catch (error) {
      console.error('Error fetching file URL:', error);
    }
  };

  const handleReminderSelect = async (reminder) => {
    console.log('Reminder selected:', reminder);
    try {
      // Update the reminder with the correct completed state
      const updatedReminder = {
        ...reminder,
        completed: reminder.status === 'COMPLETED'
      };
      setSelectedReminder(updatedReminder);
      
      // Track reminder view
      trackEvent('reminder_view', {
        reminder_id: reminder.id,
        reminder_type: reminder.category,
        reminder_status: reminder.status
      });
      
      // If this is a pending reminder, mark it as acknowledged
      if (reminder.status === 'PENDING') {
        // Update the status to mark it as acknowledged
        await updateNotification(reminder.id, { 
          status: 'ACKNOWLEDGED'
        });
        
        // Track status change
        trackEvent('reminder_status_change', {
          reminder_id: reminder.id,
          old_status: 'PENDING',
          new_status: 'ACKNOWLEDGED',
          reminder_type: reminder.category
        });
        
        // Update local state to remove the blue dot
        setReminders(prevReminders => 
          prevReminders.map(r => 
            r.id === reminder.id 
              ? { 
                  ...r, 
                  isNew: false, 
                  status: 'ACKNOWLEDGED',
                  completed: false,
                  original: { ...r.original, status: 'ACKNOWLEDGED' } 
                }
              : r
          )
        );

        // Update selected reminder with new status
        setSelectedReminder(prev => ({
          ...prev,
          isNew: false,
          status: 'ACKNOWLEDGED',
          completed: false,
          original: { ...prev.original, status: 'ACKNOWLEDGED' }
        }));

        // Trigger a refresh of the notifications count in the navbar
        if (window.updateNotificationCount) {
          window.updateNotificationCount();
        }
      }
      
      if (reminder?.original?.file_info?.id) {
        fetchFileUrl(reminder.original.file_info.id);
      } else {
        setFileUrl(null);
      }
    } catch (error) {
      console.error('Error updating reminder status:', error);
    }
  };

  const handleFileSearchClick = () => {
    setIsFileSearchOpen(!isFileSearchOpen);
  };

  const handleChatHistoryClick = () => {
    setIsChatSidebarOpen(!isChatSidebarOpen);
  };

  const handleReminderStatusChange = async (reminder, e) => {
    try {
      const newStatus = e.target.checked ? 'COMPLETED' : 'ACKNOWLEDGED';
      
      // Update server first
      await updateNotification(reminder.id, {
        status: newStatus
      });

      // Track status change
      trackEvent('reminder_status_change', {
        reminder_id: reminder.id,
        old_status: reminder.status,
        new_status: newStatus,
        reminder_type: reminder.category,
        via: 'checkbox'
      });

      // Update local state after successful server update
      const updatedReminders = reminders.map(r =>
        r.id === reminder.id ? { 
          ...r, 
          completed: newStatus === 'COMPLETED',
          status: newStatus,
          original: {
            ...r.original,
            status: newStatus
          }
        } : r
      );
      setReminders(updatedReminders);

      // Update selected reminder if it's the one being modified
      if (selectedReminder?.id === reminder.id) {
        setSelectedReminder(prev => ({
          ...prev,
          completed: newStatus === 'COMPLETED',
          status: newStatus,
          original: {
            ...prev.original,
            status: newStatus
          }
        }));
      }
    } catch (error) {
      console.error('Error updating reminder status:', error);
      // Revert the checkbox if the server update fails
      const revertedReminders = reminders.map(r =>
        r.id === reminder.id ? { ...r, completed: reminder.status === 'COMPLETED' } : r
      );
      setReminders(revertedReminders);
    }
  };

  const handleEditClick = () => {
    console.log('Edit button clicked');
    console.log('Selected reminder:', selectedReminder);
    console.log('Selected reminder due date:', selectedReminder?.original?.due_date);
    console.log('Is editing:', isEditing);
    
    if (!selectedReminder) {
      console.error('No reminder selected');
      return;
    }

    // Track edit start
    trackEvent('reminder_edit_start', {
      reminder_id: selectedReminder.id,
      reminder_type: selectedReminder.category,
      reminder_status: selectedReminder.status
    });
    
    // Format the due date to YYYY-MM-DD for the date input
    const formattedDueDate = selectedReminder?.original?.due_date ? 
      selectedReminder.original.due_date.split('T')[0] : 
      new Date().toISOString().split('T')[0];
    
    setIsEditing(true);
    const editedValues = {
      ...selectedReminder,
      title: selectedReminder.title,
      due_date: formattedDueDate,
      priority: selectedReminder?.priority || 'LOW',
      status: selectedReminder?.status || 'PENDING',
      category: selectedReminder?.category || 'CUSTOM',
      original: {
        ...selectedReminder.original,
        description: selectedReminder.original?.description || ''
      }
    };
    console.log('Setting edited reminder to:', editedValues);
    setEditedReminder(editedValues);
  };

  const handleSaveClick = async () => {
    console.log('Save button clicked');
    console.log('Edited reminder:', editedReminder);
    try {
      await updateNotification(selectedReminder.id, {
        title: editedReminder.title,
        description: editedReminder.original?.description,
        due_date: editedReminder.due_date,
        priority: editedReminder.priority,
        status: editedReminder.status,
        type: editedReminder.category
      });

      // Track edit completion
      trackEvent('reminder_edit_complete', {
        reminder_id: selectedReminder.id,
        reminder_type: editedReminder.category,
        old_status: selectedReminder.status,
        new_status: editedReminder.status,
        old_priority: selectedReminder.priority,
        new_priority: editedReminder.priority,
        old_type: selectedReminder.category,
        new_type: editedReminder.category,
        fields_changed: [
          selectedReminder.title !== editedReminder.title ? 'title' : null,
          selectedReminder.original?.description !== editedReminder.original?.description ? 'description' : null,
          selectedReminder.original?.due_date !== editedReminder.due_date ? 'due_date' : null,
          selectedReminder.priority !== editedReminder.priority ? 'priority' : null,
          selectedReminder.status !== editedReminder.status ? 'status' : null,
          selectedReminder.category !== editedReminder.category ? 'type' : null,
        ].filter(Boolean)
      });

      // Update local state
      setReminders(prevReminders =>
        prevReminders.map(r =>
          r.id === selectedReminder.id
            ? {
                ...r,
                title: editedReminder.title,
                priority: editedReminder.priority,
                status: editedReminder.status,
                category: editedReminder.category,
                original: {
                  ...r.original,
                  description: editedReminder.original?.description,
                  due_date: editedReminder.due_date,
                  priority: editedReminder.priority,
                  status: editedReminder.status,
                  type: editedReminder.category
                }
              }
            : r
        )
      );

      setSelectedReminder(prev => ({
        ...prev,
        title: editedReminder.title,
        priority: editedReminder.priority,
        status: editedReminder.status,
        category: editedReminder.category,
        original: {
          ...prev.original,
          description: editedReminder.original?.description,
          due_date: editedReminder.due_date,
          priority: editedReminder.priority,
          status: editedReminder.status,
          type: editedReminder.category
        }
      }));

      setIsEditing(false);
    } catch (error) {
      console.error('Error updating reminder:', error);
      setError('Failed to update reminder');
      
      // Track edit failure
      trackEvent('reminder_edit_error', {
        reminder_id: selectedReminder.id,
        error_message: error.message
      });
    }
  };

  const handleCancelEdit = () => {
    // Track edit cancellation
    trackEvent('reminder_edit_cancel', {
      reminder_id: selectedReminder.id,
      reminder_type: selectedReminder.category
    });
    
    setIsEditing(false);
    setEditedReminder(null);
  };

  const fetchCsvContent = async (fileId) => {
    try {
      const response = await getFileContent(fileId);
      const result = Papa.parse(response.content, {
        header: true,
        skipEmptyLines: true
      });
      setCsvData(result.data);
      setCsvError(null);
    } catch (error) {
      console.error('Error fetching CSV content:', error);
      setCsvError('Failed to load CSV content');
    }
  };

  useEffect(() => {
    if (selectedReminder?.original?.file_info?.id && 
        selectedReminder?.original?.file_info?.file_type === 'text/csv') {
      fetchCsvContent(selectedReminder.original.file_info.id);
    } else {
      setCsvData(null);
      setCsvError(null);
    }
  }, [selectedReminder]);

  const mainContent = (
    <div className="flex h-full">
      {console.log('Rendering main content, isEditing:', isEditing)}
      {console.log('Selected reminder:', selectedReminder)}
      {console.log('Edited reminder:', editedReminder)}
      {/* Left Panel - Reminders List */}
      <div className="w-80 border-r border-gray-200 flex flex-col h-full">
        <div className="p-4 bg-white sticky top-0 z-10 border-b border-gray-200">
          <h2 className="text-lg font-bold">All Reminders</h2>
        </div>
        <div className="flex-1 overflow-y-auto">
          {loading && page === 1 ? (
            <div className="text-center py-4">Loading...</div>
          ) : error ? (
            <div className="text-red-500 text-center py-4">{error}</div>
          ) : (
            <div className="space-y-2 p-4">
              {reminders.map((reminder, index) => (
                <div
                  key={reminder.id}
                  ref={index === reminders.length - 1 ? lastReminderElementRef : null}
                  className={`p-4 rounded-lg cursor-pointer hover:bg-gray-50 ${
                    selectedReminder?.id === reminder.id ? 'bg-blue-50' : ''
                  }`}
                  onClick={() => handleReminderSelect(reminder)}
                >
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={reminder.status === 'COMPLETED'}
                      onChange={(e) => handleReminderStatusChange(reminder, e)}
                      className="h-4 w-4 rounded border-gray-300"
                    />
                    <div className={reminder.status === 'COMPLETED' ? 'line-through text-gray-500' : ''}>
                      <div className="font-medium flex items-center">
                        {reminder.title}
                        {reminder.isNew && (
                          <span data-testid="blue-dot" className="ml-2 w-2 h-2 rounded-full bg-blue-500"></span>
                        )}
                      </div>
                      <div className="text-sm text-gray-500">
                        {getDisplayValue(REMINDER_TYPE_DISPLAY, reminder.category)} • {reminder.date}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {loadingMore && (
                <div className="text-center py-4">Loading more...</div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Right Panel - Reminder Details */}
      <div className="flex-1 bg-white overflow-y-auto h-full">
        {selectedReminder ? (
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                {isEditing ? (
                  <div className="space-y-3">
                    <input
                      type="text"
                      value={editedReminder?.title || ''}
                      onChange={(e) => setEditedReminder(prev => ({ ...prev, title: e.target.value }))}
                      className="text-2xl font-bold w-full p-2 border rounded"
                    />
                    <textarea
                      value={editedReminder?.original?.description || ''}
                      onChange={(e) => setEditedReminder(prev => ({ 
                        ...prev, 
                        original: { ...prev.original, description: e.target.value }
                      }))}
                      className="w-full p-2 border rounded text-gray-600"
                      rows="3"
                      placeholder="Add a description..."
                    />
                  </div>
                ) : (
                  <>
                    <h1 className="text-2xl font-bold mb-2">{selectedReminder.title}</h1>
                    <div className="text-gray-600 space-y-1">
                      {selectedReminder?.original?.description && (
                        <p>{selectedReminder.original.description}</p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex gap-2">
                {isEditing ? (
                  <>
                    <button 
                      onClick={handleCancelEdit}
                      className="px-4 py-2 bg-gray-400 text-white rounded-lg"
                    >
                      Cancel
                    </button>
                    <button 
                      onClick={handleSaveClick}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <button 
                    onClick={handleEditClick}
                    className="px-4 py-2 bg-blue-400 text-white rounded-lg"
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              {/* Left column - Metadata */}
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-medium mb-2">Due Date</h3>
                  {isEditing ? (
                    <input
                      type="date"
                      value={editedReminder?.due_date || ''}
                      onChange={(e) => setEditedReminder(prev => ({ ...prev, due_date: e.target.value }))}
                      className="w-full p-2 border rounded"
                    />
                  ) : (
                    <span>{selectedReminder?.original?.due_date || 'No due date'}</span>
                  )}
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-medium mb-2">Priority</h3>
                  {isEditing ? (
                    <select
                      value={editedReminder?.priority || 'LOW'}
                      onChange={(e) => setEditedReminder(prev => ({ ...prev, priority: e.target.value }))}
                      className="w-full p-2 border rounded"
                    >
                      {PRIORITY_OPTIONS.map(option => (
                        <option key={option} value={option}>{getDisplayValue(REMINDER_PRIORITY_DISPLAY, option)}</option>
                      ))}
                    </select>
                  ) : (
                    <span className={`px-2 py-1 rounded ${
                      selectedReminder?.priority === 'HIGH' ? 'bg-red-100 text-red-800' :
                      selectedReminder?.priority === 'MEDIUM' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {getDisplayValue(REMINDER_PRIORITY_DISPLAY, selectedReminder?.priority) || 'Low'}
                    </span>
                  )}
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-medium mb-2">Status</h3>
                  {isEditing ? (
                    <select
                      value={editedReminder?.status || 'PENDING'}
                      onChange={(e) => setEditedReminder(prev => ({ ...prev, status: e.target.value }))}
                      className="w-full p-2 border rounded"
                    >
                      {STATUS_OPTIONS.map(option => (
                        <option key={option} value={option}>{getDisplayValue(REMINDER_STATUS_DISPLAY, option)}</option>
                      ))}
                    </select>
                  ) : (
                    <span className="px-2 py-1 rounded bg-blue-100 text-blue-800">
                      {getDisplayValue(REMINDER_STATUS_DISPLAY, selectedReminder?.status) || 'Pending'}
                    </span>
                  )}
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-medium mb-2">Type</h3>
                  {isEditing ? (
                    <select
                      value={editedReminder?.category || 'CUSTOM'}
                      onChange={(e) => setEditedReminder(prev => ({ ...prev, category: e.target.value }))}
                      className="w-full p-2 border rounded"
                    >
                      {TYPE_OPTIONS.map(option => (
                        <option key={option} value={option}>{getDisplayValue(REMINDER_TYPE_DISPLAY, option)}</option>
                      ))}
                    </select>
                  ) : (
                    <span>{getDisplayValue(REMINDER_TYPE_DISPLAY, selectedReminder?.original?.type) || 'General'}</span>
                  )}
                </div>
              </div>

              {/* Right column - Document Preview */}
              <div className="bg-gray-50 rounded-lg overflow-hidden">
                {selectedReminder?.original?.file_info && fileUrl ? (
                  selectedReminder.original.file_info.file_type === 'text/csv' ? (
                    <div className="p-4 overflow-auto h-[600px]">
                      {csvError ? (
                        <div className="text-red-500 text-center py-4">{csvError}</div>
                      ) : !csvData ? (
                        <div className="text-gray-500 text-center py-4">Loading CSV content...</div>
                      ) : (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {Object.keys(csvData[0] || {}).map((header, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {csvData.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {Object.values(row).map((cell, cellIndex) => (
                                  <td key={cellIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ) : (
                    <embed 
                      src={fileUrl}
                      type={selectedReminder.original.file_info.file_type}
                      width="100%"
                      height="600px"
                      className="border-0"
                    />
                  )
                ) : (
                  <div className="flex items-center justify-center h-[600px] text-gray-500">
                    {fileUrl === null ? 'No document available' : 'Loading document...'}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Select a reminder to view details
          </div>
        )}
      </div>
    </div>
  );

  // Early return if there's an error
  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="dashboard h-screen">
      <LeftMenu 
        onChatHistoryClick={handleChatHistoryClick}
        onFileSearchClick={handleFileSearchClick}
        isFileSearchActive={isFileSearchOpen}
      />
      <div className="dashboard-content h-full" style={{ marginLeft: "120px" }}>
        <div className="content-wrapper h-full">
          <div className="main-content h-full">
            <FileSearch 
              isOpen={isFileSearchOpen}
              onClose={() => setIsFileSearchOpen(false)}
              style={{ display: isFileSearchOpen ? 'block' : 'none' }}
            />
            {!isFileSearchOpen && mainContent}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemindersManagement;